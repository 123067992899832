import React, { useState } from "react";
import freshTeam from "../../../assets/Freshteam-gray.svg";
import settings from "../../../assets/Settings-gray.svg";
import "../Settings.css";
import connectImage from "../../../assets/Disconnected.svg";
import dataBase from "../../../assets/Database.svg";
import zoho from "../../../assets/Zoho-gray.svg";
import make from "../../../assets/Make-gray.svg";
import zapier from "../../../assets/Zapier-gray (2).svg";
import bullHorn from "../../../assets/Bullhorn-gray.svg";
import bambooHR from "../../../assets/BambooHR-gray.svg";
import bullHorncolor from "../../../assets/Bullhorn.svg";
import bambooHRcolor from "../../../assets/bambooHr.svg";
import makecolor from "../../../assets/Make.svg";
import zohocolor from "../../../assets/Zoho.svg";
import zapiercolor from "../../../assets/Zapierlogo.svg";
import freshTeamcolor from "../../../assets/Freshteam.svg";
import databasecolor from "../../../assets/Databasecolor.svg";
import lockUnlock from "../../../assets/lock-unlock-white.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ATSExcelUpload from "../ATSIntegration/ATSExcelUpload";
import Backdrop from "../BackDrop";
import ATSSettingsCard from "./ATSSettingsCard";
import { API } from "../../../global";
import { toast } from "react-hot-toast";

const exampleList = [
  {
    title: "Bullhorn",
    subTitle:
      "Allows you to track hours worked, manage benefits enrollment, and run payroll.",
    connected: false,
    img: bullHorn,
    colorImg: bullHorncolor,
    bgImg: "#F36B21",
    active: false,
  },
  {
    title: "BambooHR",
    subTitle:
      "Allows you to track hours worked, manage benefits enrollment, and run payroll.",
    connected: false,
    img: bambooHR,
    colorImg: bambooHRcolor,
    bgImg: "#73C41D",
    active: false,
  },
  {
    title: "Freshteam",
    subTitle:
      "Allows you to track hours worked, manage benefits enrollment, and run payroll.",
    connected: false,
    img: freshTeam,
    // background: "#A1A1A1",
    colorImg: freshTeamcolor,
    bgImg: "#7052CC",
    active: false,
  },
  {
    title: "Zapier",
    subTitle:
      "Allows you to track hours worked, manage benefits enrollment, and run payroll.",
    connected: false,
    img: zapier,
    colorImg: zapiercolor,
    bgImg: "#FF4A00",
    active: false,
  },
  {
    title: "Zoho",
    subTitle:
      "Allows you to track hours worked, manage benefits enrollment, and run payroll.",
    connected: false,
    img: zoho,
    // background: "#A1A1A1",
    colorImg: zohocolor,
    bgImg: "#E42527",
    active: false,
  },
  {
    title: "make",
    subTitle:
      "Allows you to track hours worked, manage benefits enrollment, and run payroll.",
    connected: false,
    img: make,
    colorImg: makecolor,
    bgImg: "#6C00CB",
    active: false,
  },
  {
    title: "Connect to database",
    subTitle:
      "Allows you to track hours worked, manage benefits enrollment, and run payroll.",
    connected: false,
    img: dataBase,
    // background: "#A1A1A1",
    colorImg: databasecolor,
    bgImg: "#00C22D",
    active: false,
  },
  {
    title: "Unlock ATS Integration",
    subTitle:
      "Upgrade to our premium plan to access and activate our advanced ATS options.",
    connected: false,
    img: lockUnlock,
    background: "#A1A1A1",
    colorImg: bambooHRcolor,
    bgImg: "#F36B21",
    active: false,
  },
];

const ATSIntegration = () => {
  const [connectedButton, setConnectedButton] = useState(false);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [newConfig, setNewConfig] = useState(false);
  const [imagebgcolor, setImagebgcolor] = useState("");
  const [colorImg, setColorImg] = useState("");
  const [ats_client_id, setAts_client_id] = useState("");
  const [ats_client_secret, setAts_client_secret] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleBackdropClose = () => {
    setIsOpen(false);
  };

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setTitle(data.title);
    // setImage(data.img)
    setImagebgcolor(data.bgImg);
    setColorImg(data.colorImg);
  };

  const [list] = useState(exampleList);

  const connectLink = (data, index) => {
    const indexId = list.findIndex((obj) => {
      return data.title === obj.title;
    });
    list[indexId].connected = list[indexId].connected ? false : true;
    setConnectedButton(!connectedButton);
  };

  const getATSConfiguration = (data) => {
    fetch(
      `${API}/admin/tenant/setting/all/${localStorage.getItem("tenant-id")}/${
        data.title
      }`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((val) => {
        if (val.success === true) {
          if (val.data.length === 1) {
            setNewConfig(true);
            localStorage.setItem("ats-settings-id", val.data[0]._id);
            setAts_client_id(val.data[0].client_id);
            setAts_client_secret(val.data[0].client_secret);
          } else {
            setNewConfig(false);
            setAts_client_id("");
            setAts_client_secret("");
          }
        } else {
          toast.error(`${val.err}`);
        }
      });
    setNewConfig(false);
  };

  return (
    <div className="ats-main-container">
      <Row>
        <Col>
          <p className="mt-5 custom-datatable-title">Import Data</p>
        </Col>
      </Row>
      <ATSExcelUpload />

      <Row>
        <Col>
          <p className="mt-5 custom-datatable-title">
            Choose the ATS you would like to integrate with Talentou (Please upgrade your plan in order to unlock the ATS integration.)
          </p>
        </Col>
      </Row>

      <div>
        <Row>
          {exampleList.map((data, index) => (
            <Col lg={3}>
              <div className="card" style={{ margin: "5px" }}>
                <div className="card-body ats-card-body">
                  <div
                    className="icon_img"
                    style={{
                      background: data.background,
                    }}
                  >
                    <img src={data.img} alt="" />
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <p
                      style={{
                        fontSize: "20px",
                        color: "#333333",
                        fontWeight: "bold",
                        margin: "0px",
                      }}
                    >
                      {data.title}
                    </p>
                    <p style={{ fontSize: "12px", color: "#A1A1A1" }}>
                      {data.subTitle}
                    </p>
                    <hr className="solid"></hr>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <button style={{border:"none",background:"none"}}
                        onClick={() => {
                          handleShow(data);
                          handleOpen();
                          getATSConfiguration(data);
                        }}
                        >
                        <img
                          src={settings}
                          alt=""
                          
                        />
                        </button>
                        
                      </div>
                      {data.active ? (
                        <>
                          <button
                            className="connectedButton"
                            onClick={() => connectLink(data, index)}
                          >
                            Upgrade My plan
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="connectButton"
                            onClick={() => connectLink(data, index)}
                          >
                            <img src={connectImage} alt="" />
                            <span style={{ paddingLeft: "15px" }}>Connect</span>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      {show ? (
        <ATSSettingsCard
          title={title}
          setShow={setShow}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          imagebgcolor={imagebgcolor}
          handleClose={handleClose}
          colorImg={colorImg}
          handleBackdropClose={handleBackdropClose}
          newConfig={newConfig}
          ats_client_id={ats_client_id}
          ats_client_secret={ats_client_secret}
        />
      ) : (
        ""
      )}
      <Backdrop isOpen={isOpen} onClose={handleBackdropClose} />
    </div>
  );
};

export default ATSIntegration;
