import React, { useEffect, useState, useMemo } from "react";
import mailIcon from "../../assets/mailbox.svg";
import copyUrl from "../../assets/copyIcon.svg";
import copyUrlDisabled from "../../assets/copyIconDisabled.svg";
import playrec from "../../assets/playIconGreen.svg";
import genotp from "../../assets/regenPurple.svg";
import { Tooltip } from "react-tooltip";
import { useNavigate, useParams } from "react-router";
import "./Candidatelist.css";
import { API } from "../../global";
import toast from "react-hot-toast";
import playrecDisabled from "../../assets/playIconGrey.svg";
// import resume from "../../assets/resume-icon.svg";
import Nav from "../Reusable/Navbar/Nav";
import SearchInput from "../Reusable/SearchInput";
// import Modal from "react-bootstrap/Modal";
import { Modal } from "react-responsive-modal";
import resendOTP from "../../assets/resendOtp.svg";

import Footer from "../Footer/Footer";
import moment from "moment";
import SideMenu from "../Reusable/SideMenu/SideMenu";
import mailIconImage from "../../assets/icons/mail.svg";
import notAligned from "../../assets/Not-Aligned.svg";
import Aligned from "../../assets/Aligned-candidate.svg";
import inProgress from "../../assets/In-Progress.svg";
import locationImage from "../../assets/icons/location.svg";
import callImage from "../../assets/icons/phone.svg";
import ProgressBarComponent from "../Reusable/ProgressBarComponent";
import resendLink from "../../assets/Resend Link.svg";
import AISuggestion from "./AISuggestion";
import CandidateListTable from "./CandidateListTable";
import Scheduleinterview from "./Scheduleinterview";
import CandidateHeader from "./CandidateHeader";
import ConfirmModal from "../Reusable/ConfirmModal/ConfirmModal";
// import ConfirmModalImg from "../../assets/ConfirmModalImg.svg";
import { ThreeDots } from "react-loader-spinner";

const customStylesReactModal = {
  modal: {
    maxWidth: "525px", // Set your custom width here
  },
  closeIcon: {
    display: "none", // Hide the close icon
  },
};

const customStylesReschedule = {
  modal: {
    maxWidth: "650px", // Set your custom width here
  },
  closeIcon: {
    display: "none", // Hide the close icon
  },
};

export default function Candidatelist() {
  const [canlist, setCanlist] = useState([]);
  // const [candidatelist,setCandidatelist] = useState([])
  const { jobid } = useParams();
  const [job_role, setJob_role] = useState("");
  const [loading, setLoading] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [showCancel, setShowCancel] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [candidateName, setcandidateName] = useState("");
  const navigate = useNavigate();
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [candidateId, setCandidateId] = useState("");
  const [quesCount, setQuesCount] = useState("");
  const [quesDuration, setQuesDuration] = useState("");
  const user_role = localStorage.getItem("current_role_key");
  const [currentPage, setCurrentPage] = useState(1);
  const [inviteLoading, setInviteLoading] = useState(false);
  const rowsPerPage = 13;
  const [interviewScheduleId, setInterviewScheduleId] = useState(null);
  const [comment, setComments] = useState("");
  const [rescheduleLoading, setRescheduleLoading] = useState(false);
  const [loadingIndex, setLoaidngIndex] = useState(null);
  const [aiCandidateData, setAICandidateData] = useState([]);
  const [otpLoading, setOtpLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  // const [loadSearch, setLoadSearch] = useState(false);
  const [searchText, setSearchText] = useState(null);

  useEffect(() => {
    getCandidateDetails()
    getAiCandidates();
    console.log(jobid, "jobid");
    // eslint-disable-next-line
  }, [jobid]);

  const getCandidateDetails =()=>{
    setLoading(true);
    getCandidates();

  }

  const getAiCandidates = () => {
    // setLoading(true);
    fetch(`${API}/admin/candidate/ai-suggestion?job_order_id=${jobid}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((val) => {
        // setLoading(false);

        if (val.success === true) {
          setAICandidateData(val.result);
        } else {
          if (val.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(`${val.err}`);
          }
        }
      });
  };

  const handleChange = (e) => {
    setComments(e.target.value);
    // setCloseSearch(true);
  };

  const getCandidates = () => {
    
    fetch(`${API}/admin/candidate/list?job_order_id=${jobid}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((val) => {
        setLoading(false);
        setSearchLoading(false);

        if (val.success === true) {
          setJob_role(val.result.job_order[0].job_role);
          setCanlist(val.result.candidates);
          setInviteLoading(false)

        } else {
          setLoading(false);
          setSearchLoading(false);
          if (val.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(`${val.error.msg}`);
          }
        }
      });
  };

  const copyLink = (obj) => {
    navigator.clipboard.writeText(obj.latest_interview.interview_link);
    toast.success("Link Copied");
  };

  const openOffcanvas = (type, candidate, index) => {
    setInviteLoading(true);
    setLoaidngIndex(index);

    fetch(`${API}/admin/questionnaire/list?job_order_id=${jobid}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setInviteLoading(false);
        if (response.success === true) {
          if (response.result.questionnaires.length !== 0) {
            setQuesCount(
              response.result.questionnaires.map((a) => a.questions.length)
            );
            const durationSum = response.result.questionnaires.reduce(
              (accumulator, currentObject) => {
                return accumulator + currentObject.total_duration;
              },
              0
            );
            setQuesDuration(durationSum / 60);
            // setShowOffcanvas(true);

            if (type === "multi") {
              setShowOffcanvas(true);
            } else {
              setcandidateName(
                candidate?.candidate_id?.name
                  ? candidate?.candidate_id?.name
                  : candidate?.name
              );
              setCandidateId(
                candidate?.candidate_id?._id
                  ? candidate?.candidate_id?._id
                  : candidate?._id
              );
              setShowOffcanvas(true);
            }
          } else {
            toast.error(
              "Sorry, no questionaire found for this Job Order, please create one and try again."
            );
          }
        } else {
          if (response.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(`${response.error.msg}`);
          }
        }
      });
  };

  const regenOtp = () => {
    setOtpLoading(true);
    const interview_scheduleid = { interview_schedule_id: interviewScheduleId };
    fetch(`${API}/admin/interview/schedule/resend-otp`, {
      method: "POST",
      body: JSON.stringify(interview_scheduleid),
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          handleCloseModal();
          toast.success("OTP regenerated");
          getCandidates();
          setOtpLoading(false);
        } else {
          handleCloseModal();

          if (res.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(res.error.msg);
          }
          setOtpLoading(false);
        }
      });
  };

  const handleOpenModal = (a) => {
    setShowModal(true);
    setInterviewScheduleId(a?.latest_interview?._id);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCopyClick = (data) => {
    navigator.clipboard.writeText(data);
    toast.success("Text copied to clipboard!");
  };

  const contactColumn = (row) => {
    return (
      <>
        <Tooltip
          id="email-column-tooltip"
          variant="light"
          className="candidate-tooltip"
          border="1px solid #7C7B7B"
        />
        <div className="d-flex gap-3">
          <button
            className="icon-div"
            onClick={() => handleCopyClick(row?.contact_number)}
          >
            <img
              src={callImage}
              alt="phone-no"
              data-tooltip-id="email-column-tooltip"
              data-tooltip-content={`${
                row?.contact_number ? row.contact_number : ""
              }`}
              data-tooltip-place="top"
              className="action-icon"
            />
          </button>

          <button
            className="icon-div"
            onClick={() => handleCopyClick(row?.email)}
          >
            <img
              src={mailIconImage}
              alt="email"
              data-tooltip-id="email-column-tooltip"
              data-tooltip-content={`${row._id && row?.email}`}
              data-tooltip-place="top"
              className=" action-icon"
            />
          </button>
          <button className="icon-div">
            <img
              src={locationImage}
              alt="location"
              data-tooltip-id="email-column-tooltip"
              data-tooltip-content={`${row?.location ? row?.location : ""}`}
              data-tooltip-place="top"
              className="cursor-pointer p-1"
            />
          </button>
        </div>
      </>
    );
  };

  const openCancel = (data) => {
    setShowCancel(true);
    setInterviewScheduleId(data?.latest_interview?._id);
  };

  const actionColumn = (a, index) => {
    return (
      <>
        <Tooltip
          id="action-column-tooltip"
          variant="light"
          className="candidate-tooltip"
          border="1px solid #7C7B7B"
        />
        <div className="d-flex gap-1">
          {user_role !== "talentou_tech_lead" && (
            <>
              {a?.latest_interview?.status === "scheduled" ||
              a?.latest_interview?.status === "completed" ||
              a?.latest_interview?.status === "in_progress" ||
              a?.latest_interview?.status === "in_complete" ? (
                <button
                  style={{ background: "none", border: "none" }}
                  onClick={() => openCancel(a)}
                >
                  <img
                    id="imgId"
                    src={resendLink}
                    alt="Generate Link"
                    data-tooltip-id="action-column-tooltip"
                    data-tooltip-content="Resend Link"
                    data-tooltip-place="top"
                  />
                </button>
              ) : (
                <>
                  {inviteLoading && loadingIndex === index ? (
                    <ThreeDots
                      visible={true}
                      height="30"
                      width="30"
                      color="#600097"
                      radius="9"
                      ariaLabel="three-dots-loading"
                      wrapperClass=""
                    />
                  ) : (
                    <button
                      style={{ background: "none", border: "none" }}
                      disabled={a.latest_interview}
                      onClick={() => openOffcanvas("single", a, index)}
                    >
                      <img
                        id="imgId"
                        src={mailIcon}
                        style={{
                          opacity:
                            a?.latest_interview?.status === "scheduled" ||
                            a?.latest_interview?.status === "completed" ||
                            a?.latest_interview?.status === "in_progress" ||
                            a?.latest_interview?.status === "in_complete"
                              ? 0.3
                              : 1,
                        }}
                        alt="Generate Link"
                        data-tooltip-id="action-column-tooltip"
                        data-tooltip-content="Generate Link and Send Email"
                        data-tooltip-place="top"
                      />
                    </button>
                  )}
                </>
              )}
            </>
          )}
          {user_role !== "talentou_tech_lead" ? (
            <>
              {a?.latest_interview?.status === "completed" ||
              a?.latest_interview?.status === undefined ||
              a?.latest_interview?.status === "" ? (
                <button
                  style={{ border: "none", background: "none" }}
                  onClick={() =>
                    a.status === "completed"
                      ? toast.error("Sorry, the interview has been completed")
                      : ""
                  }
                >
                  <img
                    src={copyUrlDisabled}
                    style={{ cursor: "pointer", opacity: "0.3" }}
                    alt="Copy URL"
                    data-tooltip-id="action-column-tooltip"
                    data-tooltip-content="Copy Link"
                    data-tooltip-place="top"
                  />
                </button>
              ) : (
                <button
                  onClick={() => copyLink(a)}
                  style={{ border: "none", background: "none" }}
                >
                  <img
                    src={copyUrl}
                    style={{ cursor: "pointer" }}
                    alt="Copy URL"
                    data-tooltip-id="action-column-tooltip"
                    data-tooltip-content="Copy Link"
                    data-tooltip-place="top"
                  />
                </button>
              )}
            </>
          ) : (
            ""
          )}

          {user_role !== "talentou_tech_lead" && (
            <>
              <Tooltip
                id="otp-column-tooltip"
                variant="light"
                className="candidate-tooltip"
                border="1px solid #7C7B7B"
              />
              {/* <div className="d-flex gap-2"> */}
              <button
                style={{ background: "none", border: "none" }}
                onClick={() =>
                  a?.latest_interview?.status === "completed"
                    ? toast.error("Sorry, the interview has been completed")
                    : handleOpenModal(a)
                }
                disabled={
                  !a?.latest_interview?._id ||
                  a?.latest_interview?.status === "completed"
                }
              >
                <img
                  src={genotp}
                  data-tooltip-id={
                    !a?.latest_interview?._id ||
                    a?.latest_interview?.status === "completed"
                      ? ""
                      : "otp-column-tooltip"
                  }
                  data-tooltip-content={a?.latest_interview?.candidate_otp}
                  alt="regenerate-icon"
                  style={{
                    cursor: "pointer",
                    opacity:
                      !a?.latest_interview?._id ||
                      a?.latest_interview?.status === "completed"
                        ? 0.3
                        : 1,
                  }}
                />
              </button>
              {/* </div> */}
            </>
          )}

          {a?.latest_interview?.status === "completed" ||
          a?.latest_interview?.status === "in_progress" ||
          a?.latest_interview?.status === "in_complete" ? (
            <button
              style={{ background: "none", border: "none" }}
              onClick={() => navigateUser(a)}
            >
              <img
                src={playrec}
                style={{ cursor: "pointer" }}
                alt="Play Recording"
                data-tooltip-id="action-column-tooltip"
                data-tooltip-content="View Recordings"
                data-tooltip-place="top"
              />
            </button>
          ) : (
            <button style={{ background: "none", border: "none" }}>
              <img
                src={playrecDisabled}
                alt="Play Recording"
                data-tooltip-id="action-column-tooltip"
                data-tooltip-content="View Recordings"
                data-tooltip-place="top"
              />
            </button>
          )}
          {/* {
            user_role !== "2" ? <img
            src={resume}
            style={{ cursor: "pointer" }}
            alt="Resume Parser"
            data-tooltip-id="action-column-tooltip"
            data-tooltip-content="Resume Parser"
            data-tooltip-place="top"
            onClick={() => navigate("/interview/candidatelist/resumeparser")}
          /> : ""
          }
           */}
        </div>
      </>
    );
  };

  const navigateUser = (a) => {
    localStorage.setItem("interview-id", a.latest_interview.interview_id);
    // navigate(`/interview/recordings/${a?.latest_interview.interview_id}`);
    navigate(`/interview/view-recordings/${a?.latest_interview.interview_id}`);
  };
  const statusSort = (rowA, rowB) => {
    if (
      (rowA?.latest_interview?.status == null ||
        rowA?.latest_interview?.status === "undefined") &&
      (rowB?.latest_interview?.status == null ||
        rowB?.latest_interview?.status === "undefined")
    ) {
      return 0;
    }
    if (
      rowA?.latest_interview?.status === "completed" ||
      rowB?.latest_interview?.status === "Interview Completedcompleted"
    ) {
      return 1;
    }
    if (
      rowA?.latest_interview?.status === "scheduled" ||
      rowB?.latest_interview?.status === "scheduled"
    ) {
      return 1;
    }
    if (
      rowA?.latest_interview?.status === "in_progress" ||
      rowB?.latest_interview?.status === "in_progress"
    ) {
      return 1;
    }
  };

  // const showRelevance = (row, i) => {
  //   return (
  //     <>
  //       {
  //         (i % 2) === 0 ?
  //           <div className="d-flex gap-2">
  //             <span className='fa fa-star checked rel-star' />
  //             <span className='fa fa-star checked rel-star' />
  //             <span className='fa fa-star checked rel-star' />
  //             <span className='fa fa-star' />
  //             <span className='fa fa-star' />
  //           </div>
  //           : <div className="d-flex gap-2">
  //           <span className='fa fa-star checked rel-star' />
  //           <span className='fa fa-star checked rel-star' />
  //           <span className='fa fa-star rel-star' />
  //           <span className='fa fa-star rel-star' />
  //           <span className='fa fa-star rel-star' />
  //         </div>
  //       }
  //     </>

  //   )

  // }

  const nameColumn = (row) => {
    return (
      <>
        <Tooltip
          id="name-column-tooltip"
          variant="light"
          className="candidate-tooltip"
          border="1px solid #7C7B7B"
        />
        <div
          data-tooltip-id="name-column-tooltip"
          data-tooltip-content={`${row?.name}`}
          data-tooltip-place="top"
          className="custom-table-cell"
        >
          {row?.name}
        </div>
      </>
    );
  };

  const skillsColumn = (row) => {
    return (
      <>
        <Tooltip
          id="skills-column-tooltip"
          variant="light"
          className="candidate-tooltip"
          border="1px solid #7C7B7B"
        />
        <div
          data-tooltip-id="skills-column-tooltip"
          data-tooltip-content={`${row?.skills}`}
          data-tooltip-place="top"
          className="custom-table-cell"
        >
          {row?.skills.map((skill, index) => (
            <span key={index}>{(index ? ", " : "") + skill}</span>
          ))}
        </div>
      </>
    );
  };

  const atsStatus = (row) => {
    console.log(row, "row");
    return (
      <>
        <Tooltip
          id="skills-column-tooltip"
          variant="light"
          className="candidate-tooltip"
          border="1px solid #7C7B7B"
        />
        <div
          data-tooltip-id="skills-column-tooltip"
          data-tooltip-content={`${row?.ats_status}`}
          data-tooltip-place="top"
          className="custom-table-cell"
        >
          {row?.ats_status}
        </div>
      </>
    );
  };

  const columns = [
    // {
    //   name: "S.No",
    //   sortable: true,
    //   //
    //   selector: (row, i) => i + 1,
    //   cell: (row, index) => {
    //     return calculateSerialNumber(index);
    //   },
    //   options: { sortIcon: "show" },
    //   center: true,
    // },
    {
      name: "Name",
      width: "150px",
      asc: true,
      sortable: true,
      cell: (row) => nameColumn(row),
      selector: (row) => row?.name,
      center: true,
    },
    {
      name: "Skills",
      width: "150px",
      cell: (row) => skillsColumn(row),
      selector: (row) => row?.skills,
      center: true,
    },

    {
      name: "Contact",
      cell: (row) => contactColumn(row),
      center: true,
    },
    {
      name: "Experience",
      cell: (row) => row?.experience,
      center: true,
    },
    {
      name: "ATS Status",

      cell: (row) => atsStatus(row),
      center: true,
    },

    {
      name: "Interview Status",
      selector: (row) => row?.latest_interview.status,

      sortable: true,
      sortFunction: statusSort,
      cell: (row) => {
        if (row?.latest_interview?.status === "completed") {
          return <span className="badge completed-color">Completed</span>;
        } else if (row?.latest_interview?.status === "in_progress") {
          return <span className="badge inProgress-color">In Progress</span>;
        } else if (row?.latest_interview?.status === "scheduled") {
          return <span className="badge scheduled-color">Scheduled</span>;
        } else if (row?.latest_interview?.status === "in_complete") {
          return <span className="badge inComplte-color">Incomplete</span>;
        } else {
          return "--";
        }
      },
      center: true,
    },
    {
      name: "Review",

      cell: (row) => reviewColumn(row),
      center: true,
    },

    {
      name: "Actions",
      //
      cell: (a, index) => actionColumn(a, index),
      center: true,
    },
  ];

  const rowDisabledCriteria = (row) =>
    row.status === "Interview Completed" && true;

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#F8EBFF40 0% 0% no-repeat padding-box",
      },
    },
    rows: {
      style: {
        backgroundColor: "#F8EBFF40 0% 0% no-repeat padding-box",
        textAlign: "center",
      },
    },
    cells: {
      style: {
        fontSize: "14.5px",
        borderBottom: " 1px solid #eee7f3",
      },
    },
  };

  const filteredItems = canlist.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const ratingElement = (count, isAligned) => {
    const defaultStars = 5;

    const newArray = Array.from({ length: defaultStars }, (_, index) => (
      <>
        {" "}
        <Tooltip
          id="rating-column-tooltip"
          variant="light"
          className="candidate-tooltip"
          border="1px solid #7C7B7B"
        />
        <span
          key={index}
          className={`fa fa-star ${
            isAligned === undefined
              ? "disabled-star"
              : index < count
              ? "checked"
              : ""
          }`}
          style={{ cursor: "pointer" }}
          data-tooltip-id="rating-column-tooltip"
          data-tooltip-content={`Overall Rating: ${count}`}
        ></span>
      </>
    ));

    return newArray;
  };

  const alignedElement = (isAligned) => {
    return (
      <>
        <Tooltip
          id="aligned-column-tooltip"
          variant="light"
          className="candidate-tooltip"
          border="1px solid #7C7B7B"
        />
        <img
          src={
            isAligned ? Aligned : isAligned === false ? notAligned : inProgress
          }
          data-tooltip-id="aligned-column-tooltip"
          data-tooltip-content={
            isAligned !== undefined
              ? isAligned === true
                ? "Aligned"
                : "Not Aligned"
              : "In Progress"
          }
          alt=""
          style={{ cursor: "pointer" }}
        />
      </>
    );
  };

  const reviewColumn = (row) => {
    const aligned = row?.latest_interview?.aligned;
    const overallRating = row?.latest_interview?.overall_rating;

    if (row?.latest_interview?.status === "completed") {
      return (
        <>
          {alignedElement(aligned)}
          <div className="aligned-rating">|</div>
          {ratingElement(overallRating, aligned)}
        </>
      );
    } else if (row?.latest_interview?.status === "in_complete") {
      return (
        <>
          <img src={notAligned} alt="" />
          <div className="aligned-rating">|</div>
          {ratingElement(overallRating, aligned)}
        </>
      );
    } else if (
      row?.latest_interview?.status === "scheduled" ||
      row?.latest_interview?.status === undefined
    ) {
      return "--";
    }
  };

  //   const count = row?.latest_interview?.overall_rating;
  //   const newArray = Array.from({ length: count }, (_, index) => index + 1);
  //   if (count) {
  //     return newArray.map(() => <span className="fa fa-star checked"></span>);
  //   } else {
  //     return
  //     <>
  //     <span className="fa fa-star"></span>
  //     <span className="fa fa-star"></span>
  //     <span className="fa fa-star"></span>
  //     <span className="fa fa-star"></span>
  //     <span className="fa fa-star"></span>
  //     </>;
  //   }
  // };

  // const alighnColumn = (row) => {
  //   const aligned = row?.latest_interview?.aligned;
  //   if (row?.latest_interview?.status === "completed") {
  //     if (aligned) {
  //       return <img src={Aligned} alt=""/>;
  //     } else {
  //       return <img src={notAligned} alt=""/>;
  //     }
  //   } else {
  //     return <img src={inProgress} alt=""/>;
  //   }
  // };

  const ExpandedComponent = ({ data }) => {
    console.log(data, "obj");
    function formatDateToDesiredFormat() {
      const dateStr = data?.latest_interview?.created_at;
      const formattedDate = moment(dateStr, "YYYY-MM-DD HH:mm:ss").format(
        "DD MMM YYYY"
      );
      return formattedDate;
    }

    function dateCompletedFormat() {
      const completedDate = data?.latest_interview?.interview_finish_date;
      const CompformattedDate = moment(
        completedDate,
        "YYYY-MM-DD HH:mm:ss"
      ).format("DD MMM YYYY");
      return CompformattedDate;
    }

    return (
      <div className="expandable-main-container pl-5 py-2">
        <div className="expandable-container">
          <span className="expandable-label w-25">
            Interview Scheduled Date
          </span>
          <span className="expandable-value">
            {data?.latest_interview?.created_at
              ? formatDateToDesiredFormat()
              : "NA"}
          </span>
        </div>
        <div className="expandable-container">
          <span className="expandable-label w-25">
            Interview Completed Date
          </span>
          <span className="expandable-value">
            {data?.latest_interview?.interview_finish_date
              ? dateCompletedFormat()
              : "NA"}
          </span>
        </div>
        <div className="expandable-container">
          <span className="expandable-label w-25">Skills</span>
          <span className="expandable-value">
            {data?.skills[0].length === 0 ? "NA" : data?.skills.join(", ")}
          </span>
        </div>
        <div className="expandable-container">
          <span className="expandable-label w-25">Experience</span>
          <span className="expandable-value">
            {data?.experience ? data?.experience : "NA"}
          </span>
        </div>
        <div className="expandable-container">
          <span className="expandable-label w-25">Address</span>
          <span className="expandable-value">
            {data?.location ? data?.location : "NA"}
          </span>
        </div>
        {/* <div className="expandable-container">
          <span className="expandable-label w-25">Review</span>
          <span className="expandable-value">
            {data?.latest_interview?.aligned !== undefined
              ? data?.latest_interview?.aligned
                ? "aligned"
                : "not-aligned"
              : "NA"}
          </span>
        </div>
        <div className="expandable-container">
          <span className="expandable-label w-25">Overall Rating</span>
          <span className="expandable-value">
            {data?.latest_interview?.overall_rating
              ? data?.latest_interview?.overall_rating
              : "NA"}
          </span>
        </div> */}
        <div className="expandable-container">
          <span className="expandable-label w-25">Feedback</span>
          <span className="expandable-value">
            {data?.latest_interview?.feedback
              ? data?.latest_interview?.feedback
              : "NA"}
          </span>
        </div>
      </div>
    );
  };

  const cancelInterview = () => {
    setRescheduleLoading(true);
    const obj = {
      interview_schedule_id: interviewScheduleId,
      comment: comment,
    };

    fetch(`${API}/admin/interview/schedule/reschedule `, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((val) => {
        setRescheduleLoading(false);

        if (val.success === true) {
          toast.success("Link sent successfully");
          setShowCancel(false);
          getCandidates();
          setComments("");

          setInviteLoading(true)
        } else {
          if (val.error.error_field === "comment") {
            toast.error("Please add comment.");
          } else {
            toast.error(`${val.error.msg}`);
          }
          if (val.error.code === 421) {
            navigate("/error");
          }
          setRescheduleLoading(false);
        }
      });
  };

  const searchComponent = useMemo(() => {
    return (
      <SearchInput
        onFilter={(e) => setFilterText(e.target.value)}
        filterText={filterText}
      />
    );
  }, [filterText]);

  const refreshData = () => {
    getCandidates();
    getAiCandidates();
  };

  useEffect(() => {
    if (searchText === "") {
      getCandidates();
    }

    //eslint-disable-next-line
  }, [searchText]);

  const onSearch = (data) => {
    if (data.search_value) {
      fetch(`${API}/admin/candidate/search`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((val) => {
          setSearchLoading(false);

          if (val.success) {
            setCanlist(val.result.candidates);
          } else {
            if (val.error.code === 421) {
              navigate("/error");
            }
          }
        });
    } else {
      getCandidates();
    }
  };

  const isRowDisabled = (row) => {
    // console.log("row1001",row)
    return row.latest_interview;
  };

  const handleTour = () => {
    localStorage.setItem("tourGuid", true);
    navigate("/admin-dashboard");
  };

  return (
    <>
      <Nav handleTour={handleTour} />
      <Tooltip id="my-tooltip" border="1px solid #7C7B7B" />

      <div className="container-fluid text-center d-flex m-0 p-0">
        <div className="sidemenu-section">
          <SideMenu menuIndex={2} />
        </div>
        {loading ? (
          <>
            <div className="loader-div-job">
              <ProgressBarComponent />
            </div>
          </>
        ) : (
          <div className="candidate-data-section">
            <div
              className="can-table-class"
              style={{
                background: "#ffffff",
                margin: "20px",
                padding: "20px",
                borderRadius: "10px",
                // height: "100%",
              }}
            >
              <CandidateHeader
                openOffcanvas={openOffcanvas}
                filteredItems={filteredItems}
                candidates={candidates}
                job_role={job_role}
                searchComponent={searchComponent}
                canlist={canlist}
                onSearchData={onSearch}
                jobId={jobid}
                searchLoading={searchLoading}
                setSearchText={setSearchText}
                searchText={searchText}
              />

              <div className="table-wrap">
                <CandidateListTable
                  canlist={canlist}
                  columns={columns}
                  user_role={user_role}
                  filteredItems={filteredItems}
                  customStyles={customStyles}
                  rowDisabledCriteria={rowDisabledCriteria}
                  searchComponent={searchComponent}
                  ExpandedComponent={ExpandedComponent}
                  setCandidates={setCandidates}
                  rowsPerPage={rowsPerPage}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  isRowDisabled={isRowDisabled}
                  // selectDesabled={selectDesabled}
                />
              </div>

              <div className="mt-5">
                <AISuggestion
                  aiCandidateData={aiCandidateData}
                  jobId={jobid}
                  handleCopyClick={handleCopyClick}
                  refreshData={refreshData}
                />
              </div>
            </div>
            <div className="mt-5">
              <Footer />
            </div>
          </div>
        )}

        {["end"].map((placement, idx) => (
          <Scheduleinterview
            candidateName={candidateName}
            candidateId={candidateId}
            quesCount={quesCount}
            setShowOffcanvas={setShowOffcanvas}
            showOffcanvas={showOffcanvas}
            quesDuration={quesDuration}
            candidates={candidates}
            idx={idx}
            placement={placement}
            jobid={jobid}
            getCandidates={getCandidates}
            setInviteLoading={setInviteLoading}
          />
        ))}
      </div>

      <Modal
        open={showCancel}
        onClose={() => setShowCancel(false)}
        styles={customStylesReschedule}
        center
      >
        <div className="d-grid">
          <h4 className="reschedule-header">
            Are you sure you want to reschedule the interview ?
          </h4>
          <p style={{ margin: "10px" }}>
            If you choose to reschedule the interview, please be aware that you
            will lose access to the current recording if it has already been
            completed or is in progress..
          </p>
        </div>
        <div style={{ display: "grid" }}>
          <textarea
            type="text"
            onChange={handleChange}
            className="textarea-style"
            placeholder="Please provide your reason for rescheduling."
          />
          <div className="d-flex justify-content-end gap-2 mt-2">
            <button
              className="reschedule-cancel-btn"
              onClick={() => setShowCancel(false)}
            >
              Cancel
            </button>
            <button
              disabled={rescheduleLoading}
              className="reschedule-save-btn"
              onClick={() => cancelInterview()}
            >
              {rescheduleLoading ? "Loading..." : "Reschedule"}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={showModal}
        styles={customStylesReactModal}
        center
        onClose={handleCloseModal}
      >
        <ConfirmModal
          img={resendOTP}
          subTitle="Do you really want to send new OTP for this interview?"
          buttonTitle="Resend OTP"
          handleCloseModal={handleCloseModal}
          handleConfirm={regenOtp}
          loading={otpLoading}
        />
      </Modal>
    </>
  );
}
