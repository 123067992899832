import React from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import search from "../../assets/Search.svg";
import { TailSpin } from "react-loader-spinner";
import { getThemeColor } from "../Functions/commonFunctions";

const SearchInput = ({
  searchLoading,
  filterText,
  onFilter,
  onSearch,
  enableOnClick,
}) => {
  const onSearchClick = () => {
    if (enableOnClick) {
      onSearch();
    }
  };

  return (
    <>
      <InputGroup>
        <FormControl
          className="search-input py-1 px-2"
          type="text"
          placeholder="Search"
          value={filterText}
          onChange={onFilter}
        />

        {searchLoading ? (
          <div className="search-icon">
            <TailSpin
              visible={true}
              height="20"
              width="20"
              color={getThemeColor().primaryColor}
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        ) : (
          <button onClick={onSearchClick} className="search-icon">
            <img src={search} alt="" />
          </button>
        )}
      </InputGroup>
    </>
  );
};

export default SearchInput;
