import React, { useState, useEffect } from "react";
import "../Settings.css";
import { Col, Container, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import crossIcon from "../../../../src/assets/closeIcon.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import edit from "../../../../src/assets/edit.svg";
import { Tooltip } from "react-tooltip";

import { API } from "../../../global";
import { useNavigate } from "react-router";

const UserManagementForm = (props) => {

  const navigate = useNavigate()

  const [userId, setUserId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRoles, setSelectedRole] = useState([]);
  const [rolesError, setRolesError] = useState("");
  const [enablePassword, setEnablePassword] = useState(false);

  const schema = yup
    .object({
      userName: yup.string().required("Name is a required field"),
      userEmail: yup
        .string()
        .email("Invalid Email")
        .required("Email is a required field"),
      password: enablePassword
        ? yup.string().required("Password is a required field")
        : yup.string(),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (props?.selectedData?.length !== 0) {
      let user = props.selectedData;
      setValue("userName", user?.name);
      setValue("userEmail", user?.email);
      setUserId(user?._id);
      setSelectedRole(user?.type?.split(", "));
    }

    if (props.type === "add") {
      setEnablePassword(true);
    }

    // eslint-disable-next-line
  }, [props.selectedData]);

  const chooseRole = (type) => {
    if (selectedRoles && selectedRoles?.includes(type)) {
      let tempList = selectedRoles;
      tempList = tempList.filter((item) => item !== type);
      setSelectedRole(tempList);
    } else {
      setSelectedRole([...selectedRoles, type]);
    }
  };

  const updateData = (data) => {
    setLoading(true);
    let roleId = [];

    // eslint-disable-next-line
    props.roleData.map((data) => {
      // eslint-disable-next-line
      selectedRoles.map((item) => {
        if (item === data.description) {
          roleId.push(data?._id);
        }
      });
    });
    const obj = {
      role_ids: roleId,
      name: data.userName,
      email: data.userEmail,
    };
    if (props.type === "add") {
      obj.password = data.password;
    }
    if (props.type === "edit") {
      if (data.password) {
        obj.password = data.password;
      }
      obj.user_id = userId;
    }

    saveData(obj);
  };

  const getRandomChar = (charset) => {
    const randomIndex = Math.floor(Math.random() * charset.length);
    return charset[randomIndex];
  };

  const generatePassword = (e, length) => {
    e.preventDefault();
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()-_+=";

    let password = "";

    // Ensure the password contains at least one of each required character type
    password += getRandomChar("ABCDEFGHIJKLMNOPQRSTUVWXYZ"); // Add a random uppercase letter
    password += getRandomChar("abcdefghijklmnopqrstuvwxyz"); // Add a random lowercase letter
    password += getRandomChar("0123456789"); // Add a random number
    password += getRandomChar("!@#$%^&*()-_+="); // Add a random special character

    // Generate the rest of the password
    for (let i = 4; i < length; i++) {
      password += getRandomChar(charset);
    }

    // Shuffle the password characters
    password = password
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");
    setValue("password", password);
  };

  const saveData = (obj) => {
    let endPoint = "";
    let msg = "";
    if (props.type === "add") {
      endPoint = "create";
      msg = "Created";
    } else {
      endPoint = "update";
      msg = "Updated";
    }
    fetch(`${API}/admin/tenant/user/${endPoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.success) {
          props.closeMOdal();
          props.resetData();
          toast.success(`User ${msg} Successfully`);
        } else {
         
          if (res.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(res.error.msg);
          }
        }
      })
      .catch(() => {
        setLoading(false);

        toast.error("Error occurred please try again");
      });
  };

  const cancel = (e) => {
    e.preventDefault();
    props.closeMOdal();
  };

  const onSubmit = (data) => {
    if (selectedRoles.length !== 0) {
      updateData(data);
      setRolesError("");
    } else {
      setRolesError("Please select atleast one role");
    }
  };

  const enableEditPassword = (e) => {
    e.preventDefault();
    setEnablePassword(!enablePassword);
  };

  return (
    <div>
      <Tooltip
        id="user-management-form-tooltip"
        variant="light"
        className="candidate-tooltip"
        border="1px solid #7C7B7B"
      />
      <Container fluid className="bb-primary">
        <Row>
          <Col>
            <p style={{ fontSize: "18px", fontWeight: "bold" }}>
              {props?.type === "edit" ? "Edit User" : "Add user"}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="container">
              <div className="row">
                {" "}
                <div className="role-selectable-div p-0 m-0">
                  {props.roleData.map((data) => (
                    // <div
                    //   className={
                    //     selectedRoles?.includes(data?.description?.trim())
                    //       ? "col role-selected-div"
                    //       : "col role-select-div"
                    //   }
                    // >

                    <button
                      onClick={() => chooseRole(data.description)}
                      className={
                        selectedRoles?.includes(data?.description?.trim())
                          ? "col role-selected-div"
                          : "col role-select-div"
                      }
                    >
                      <p
                        className={
                          selectedRoles?.includes(data?.description?.trim())
                            ? "selected-role-text"
                            : "role-text"
                        }
                      >
                        {data.description}
                      </p>
                    </button>

                    // </div>
                  ))}
                </div>
                <p className="error-text mt-2">{rolesError}</p>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div style={{ fontSize: "18px", fontWeight: "bold" }}>
              User Details
            </div>
          </Col>
        </Row>
        <form>
          <Row>
            <Col>
              <div className="input-div-edit mt-2">
                <label className="label-style">Name</label>
                <input
                  className="input-style"
                  placeholder="Enter Name"
                  type="text"
                  id="name"
                  {...register("userName")}
                />
                <p className="error-text">{errors.userName?.message}</p>
              </div>
              <div className="input-div-edit">
                <label className="label-style">Work Email</label>
                <input
                  className="input-style"
                  placeholder="Enter Email"
                  type="text"
                  {...register("userEmail")}
                />
                <p className="error-text">{errors.userEmail?.message}</p>
              </div>
              <div className="input-div-edit">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <label className="label-style">Password</label>
                  {props.type === "edit" && (
                    <>
                      <button
                        style={{
                          marginRight: "6px",
                          border: "none",
                          background: "none",
                        }}
                        onClick={(e) => enableEditPassword(e)}
                      >
                        <img
                          style={{
                            width: "16px",
                            height: "16px",
                            cursor: "pointer",
                          }}
                          src={enablePassword ? crossIcon : edit}
                          alt=""
                          data-tooltip-id="user-management-form-tooltip"
                          data-tooltip-content={
                            enablePassword ? "Close" : "Edit"
                          }
                          data-tooltip-place="top"
                        />
                      </button>
                    </>
                  )}
                </div>
                {enablePassword && (
                  <Row>
                    <Col className="d-flex">
                      <input
                        className="input-style"
                        style={{ width: "100%" }}
                        placeholder="Enter Password"
                        type="text"
                        {...register("password")}
                      />
                      <p className="error-text">{errors.password?.message}</p>

                      <button
                        className="gen-password"
                        onClick={(e) => generatePassword(e, 10)}
                      >
                        Generate Password
                      </button>
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "20px",
                }}
              >
                <div>
                  <button className="cancel-button" onClick={(e) => cancel(e)}>
                    Cancel
                  </button>
                  <button
                    disabled={loading}
                    className="save-button"
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                  >
                    {loading ? "Saving..." : "Save"}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </Container>
    </div>
  );
};

export default UserManagementForm;
