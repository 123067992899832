import React, { useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-hot-toast";
import * as XLSX from "xlsx";
import { API } from "../../../global";
import jobOrder from "../../../assets/job-order.svg";
import candidate from "../../../assets/candidate-white.svg";
import ATSCard from "./ATSCard";
import { useState } from "react";
import JobOrderList from "./JobOrder-template.xlsx";
import CandidateList from "./CandidateList-template.xlsx";
import { useNavigate } from "react-router";

const ATSExcelUpload = () => {

  const navigate = useNavigate()

  const [jobListloading, setJobListLoading] = useState(false);
  const [candidateListloading, setCandidateListloading] = useState(false);
  const JobListInputRef = useRef(null);
  const CandidateListInputRef = useRef(null);

  const handleUploadJoblistClick = () => {
    JobListInputRef.current.click();
  };
  const handleUploadCandidateClick = () => {
    CandidateListInputRef.current.click();
  };

  const handleJobList = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;

        const workbook = XLSX.read(data, { type: "array" });

        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        const json = XLSX.utils.sheet_to_json(worksheet);

        if (json.length !== 0) {
          const template = ["Joborder_Id", "Company", "Jobrole","Location"];

          const column = Object.keys(json[0]);

          const dataCount = column.length;

          const isMatch = column.every(
            (columnName, index) => columnName === template[index]
          );

          if (isMatch && dataCount === 4) {
            const newjoborder = json.map(
              ({ Joborder_Id, Company, Jobrole,Location }) => ({
                joborder_id: Joborder_Id,
                company_name: Company,
                job_role: Jobrole,
                location:Location,
                tenant_id: localStorage.getItem("tenant-id"),
              })
            );
            handlePostJoblist(newjoborder);
          } else {
            toast.error("File format is mismatched");
          }
        } else {
          toast.error("Upload file has no data");
        }
      };
      if (e.target.files && e.target.files.length > 0) {
        reader.readAsArrayBuffer(e.target.files[0]);
      } else {
        toast.error("Please upload the file");
      }
    } else {
      toast.error("Please upload the file");
    }
  };

  const handlePostJoblist = (newjoborder) => {
    setJobListLoading(true);
    fetch(`${API}/admin/tenant/ats-settings/import-jobs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify({job_orders : newjoborder}),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          toast.success("File uploaded successfully");
          JobListInputRef.current.value = "";
          setJobListLoading(false);
        } else {
          // toast.error("File format is mismatched");
         
          if (res.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(res.error.msg);
          }
          setJobListLoading(false);
        }
      });
  };

  const handleCandidateList = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        if (json.length !== 0) {
          const template = [
            "JoborderId",
            "CandidateId",
            "Name",
            "Email",
            "Skills",
            "Experience",
            "Location",
           "ContactNumber"
          ];
          const column = Object.keys(json[0]);

          const dataCount = column.length;

          const isMatch = column.every(
            (columnName, index) => columnName === template[index]
          );

          if (isMatch && dataCount === 8) {
            const newCandidateList = json.map(
              ({
                JoborderId,
                CandidateId,
                Name,
                Email,
                Skills,
                Experience,
                Location,  
                ContactNumber            
              }) => ({
                joborder_id: JoborderId,
                candidate_id: CandidateId,
                name: Name,
                email: Email,
                skills: Skills,
                experience: Experience,
                location: Location,
                contact_number : ContactNumber,              
                tenant_id: localStorage.getItem("tenant-id"),
              })
            );
            handlePostCandidateList(newCandidateList);
          } else {
            toast.error("File format is mismatched");
          }
        } else {
          toast.error("Upload file has no data");
        }
      };

      if (e.target.files && e.target.files.length > 0) {
        reader.readAsArrayBuffer(e.target.files[0]);
      } else {
        toast.error("Please upload the file");
      }
    } else {
      toast.error("Please upload the file");
    }
  };

  const handlePostCandidateList = (newCandidateList) => {
    setCandidateListloading(true);
    fetch(`${API}/admin/tenant/ats-settings/import-candidates`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify({candidates:newCandidateList}),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          toast.success("File uploaded successfully");
          CandidateListInputRef.current.value = "";
          setCandidateListloading(false);
        } else {
          // toast.error("File format is mismatched");
          
          if (res.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(res.error.msg);
          }
          setCandidateListloading(false);
        }
      });
  };

  return (
    <div>
      <Row>
        <Col md={6}>
          <ATSCard
            title="Job Order"
            icon={jobOrder}
            description=" Download the job order template, fill it with your data,
                      and effortlessly upload for seamless spreadsheet
                      integration"
            inputRef={JobListInputRef}
            handleUploadClick={handleUploadJoblistClick}
            handleList={handleJobList}
            loading={jobListloading}
            fileRef={JobOrderList}
            fileName="Job-Order-Template"
          />
        </Col>
        <Col md={6}>
          <ATSCard
            title="Candidate List"
            icon={candidate}
            description="Download the candidate list template, fill it with your
                      data, and effortlessly upload for seamless spreadsheet
                      integration"
            inputRef={CandidateListInputRef}
            handleUploadClick={handleUploadCandidateClick}
            handleList={handleCandidateList}
            loading={candidateListloading}
            fileRef={CandidateList}
            fileName="Candidate-List-Template"
          />
        </Col>
      </Row>
    </div>
  );
};

export default ATSExcelUpload;
