import React from 'react'
import { Col, Container, Row } from "react-bootstrap";
import crossIcon from "../../../src/assets/closeIcon.svg";

const TextInput = ({placeHolder,answerData,handleKeyDown,removeKeyWord,handleInputChangeKeyWord,answerKeyword,id,row}) => {
  return (
    <div>
          <Container fluid>
            <Row>
              <Col className="p-0">
                <div className=" border border-1 skill-input-div fs-6">
                  <div>
                    <ul className="skill-input-ul p-0 ">
                    {answerData && answerData.length > 0
                        ? answerData.map((data, i) => (
                            <li className="skill-input-li" key={i}>
                              {data}
                              <span onClick={() => removeKeyWord(i)}>
                                <img
                                  className="keyword-remove"
                                  alt=""
                                  src={crossIcon}
                                />
                              </span>
                            </li>
                          ))
                        : ""}
                    </ul>
                  </div>

                  <textarea
                    type="text"
                    placeholder={placeHolder}
                    className="skill-input"
                    onKeyDown={(e) => handleKeyDown(e)}
                    onChange={(e) => handleInputChangeKeyWord(e)}
                    value={answerKeyword}
                    rows={row}
                    id={id}
                  />
                </div>
              </Col>
            </Row>
          </Container>
    </div>
  )
}

export default TextInput