import React, { useEffect, useState } from "react";
import noques from "../../../assets/noQuesAvailable.svg";
import "./AddQuestion.css";
import AddNewQuestionList from "./AddNewQuestionList";
import { API } from "../../../global";
// import { useParams } from "react-router";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";

const AddNewQuestionnaire = ({
  setBtnEnabled,
  btnenabled,
  addqueslist,
  setShowModal,
  setModalTitle,
  jobOrderList,
  jobOrderID,
  setJobOrderID,
  setQuestionnaireId,
  setQuestionnaireName,
  questionnaireName,
  questionDetails,
  mode,
  id,
  getQuestionnaire,
  choosenRole,
  jobname
}) => {
  // const { jobid } = useParams();
  const navigate = useNavigate()

  const [processing, setProcessing] = useState(false);
  const [hideContinue, setHideContinue] = useState(false);
  const [showEditBtn, setShowEditBtn] = useState(false);
  const [showAddQuestion, setShowAddQuestion] = useState(false);
  const [editQuestionnaireName, setEditQuestionnaireName] = useState(false);
  // const [enableEdit,setEnableEdit] = useState(false);

  useEffect(() => {
    if (mode === "edit") {
      setBtnEnabled(true);
      setShowEditBtn(true);
      setShowAddQuestion(true)
    }
    // eslint-disable-next-line
  }, []);

  const saveQuestionnaire = () => {
    setProcessing(true);

    const questionnaire = {
      job_order_id: jobOrderID,
      name: questionnaireName,
    };

    fetch(`${API}/admin/questionnaire/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify(questionnaire),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          setQuestionnaireId(response.result.questionnaire_id);
          setShowAddQuestion(true);
          setProcessing(false);
          setHideContinue(true);
          setBtnEnabled(true);
          setShowEditBtn(true);
        }else{
         
          if (response.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(response.error.msg)
          }
        }
      })
      .catch((err) => {
        setHideContinue(false);
      });
    setHideContinue(false);
    // setProcessing(false);
  };

  const saveQuestionnaireName = () => {
    // e.preventDefault();

    setEditQuestionnaireName(true);

    if (questionnaireName.trim()) {
      const UpdatedquestionnaireName = {
        name: questionnaireName,
        questionnaire_id: id,
      };
      fetch(`${API}/admin/questionnaire/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
        body: JSON.stringify(UpdatedquestionnaireName),
      })
        .then((res) => res.json())
        .then((response) => {
          if(response.success){
            toast.success("Questionnaire name updated successfully");
            setEditQuestionnaireName(false);
            setShowAddQuestion(true);
          }else{
            
            if (response.error.code ===  421) {
              navigate("/error");
            }else{
              toast.error(response.error.msg)
            }
          }
         
          // setShowEditBtn(true);
        })
        .catch((err) => {
          console.error("API Failed:", err);
        });
    } else {
      toast.error("Please fill the required field");
      setEditQuestionnaireName(false);
    }
  };
  // console.log(processing,"setProcessing(true);")

  console.log("question", questionDetails);
  return (
    <div className="add-ques-header-container mb-2">
      <div className="form-group  mt-2 py-3 px-4 rounded add-new-questionnaire-conatiner">
        <div className="d-flex w-100 gap-3 align-items-end">
          <div className="w-100 form-group">
            <label for="QuestionName" className="fs-6 fw-bold">
              Questionnaire Name<sup>*</sup>
            </label>
            <br />
            <div className="d-flex align-items-center">
              <input
                type="text"
                className="form-control-lg  p-2 w-100"
                id="Questionnaire Name"
                placeholder="Enter the questionnaire name"
                value={questionnaireName}
                onChange={(e) => setQuestionnaireName(e.target.value)}
                // disabled={showAddQuestion || editQuestionnaireName}
              />
              {showEditBtn && (
                <button
                  type="button"
                  className={`
              ${
                questionnaireName === ""
                  ? "edit-button-disable "
                  : "edit-button "
              } `}
                  onClick={() => saveQuestionnaireName()}
                >
                  {editQuestionnaireName ? "updating..." : "Save"}
                </button>
              )}
            </div>
          </div>
          <div className="w-100 form-group">
            <label for="QuestionName" className="fs-6 fw-bold">
              Job Order<sup>*</sup>
            </label>
            <br />
            <select
              className="form-select add-job-select p-2 "
              aria-label="Default select example"
              style={{ color: "#707070", height: "44px" }}
              disabled={
                ( showAddQuestion || choosenRole)
                  ? true
                  : false
              }
              value={jobOrderID}
              onChange={(e) => setJobOrderID(e.target.value)}
            >
       
              <option selected disabled value= {jobname? jobname: "Select the Job Order"} >
            {mode === "edit" ? jobname: "Select the Job Order"}  
              </option>
              {jobOrderList?.map((list, i) => (
                <>
                  {!list.questionnaire && (
                    <option value={list?._id}>{list.job_role}</option>
                  )}
                </>
              ))}
            </select>
          </div>
          {mode === "edit" ? (
            ""
          ) : (
            <>
              {!hideContinue && (
                <button
                  type="button"
                  className={
                 (!questionnaireName|| !jobOrderID)
                      ? "continue-button-disabled"
                      : "continue-button-enabled"
                  }
                  onClick={() => saveQuestionnaire()}
                
                >
                  {processing ? "Processing..." : "Continue"}
                </button>
              )}
            </>
          )}
        </div>
      </div>

      {addqueslist ? (
        <>
          {questionDetails &&
            questionDetails?.map((question, i) => (
              <AddNewQuestionList
                setShowModal={setShowModal}
                i={i}
                question={question.question}
                answer={question.answer_key}
                textinput={question.text_box_required}
                duration={question.maximum_duration}
                id={question._id}
                setModalTitle={setModalTitle}
                getQuestionnaire={getQuestionnaire}
              />
            ))}
        </>
      ) : (
        <div className="no-question-available">
          <img src={noques} alt="" />
          <div className="no-ques-text">
            {btnenabled
              ? "Click 'Add Question' to start building your questionnaire."
              : "To begin, enter the questionnaire name , select a job order and click the continue button"}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddNewQuestionnaire;
