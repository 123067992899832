import React from "react";
import DurationButton from "../Reusable/DurationButton/DurationButton";
import SwitchComponent from "../Reusable/SwitchComponent";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import ProgressBarComponent from "../Reusable/ProgressBarComponent";

import { API } from "../../global";
import { useNavigate } from "react-router";

const InterviewSettings = () => {

  const navigate = useNavigate()

  const [interviewDuration, setInterviewDuration] = useState(0);
  const [waitDuration, setWaitDuration] = useState(0);
  const [linkExpiryTime, setLinkExpiryTime] = useState(0);
  const [faceDetection, setFaceDetection] = useState(false);
  const [tabSwichDetection, setTabSwitchDetection] = useState(false);
  const [tenantConfigId, setTenantConfigId] = useState("");
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true)

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line
  }, []);

  const fetchData = () => {
    fetch(`${API}/admin/tenant/interview-setting`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setInitialLoading(false)
          setTenantConfigId(res.result?._id);
          setInterviewDuration(res.result?.max_duration_per_qn);
          setWaitDuration(res.result?.max_wait_duration_after_qn_read);
          setLinkExpiryTime(res.result?.link_expire_duration);
          setTabSwitchDetection(res.result?.fraud_detection);
          setFaceDetection(res.result?.face_detection);
        }else{
          if (res.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(res.error.msg);
          }
        }
      })
      .catch(() => {
        setInitialLoading(false)
        toast.error("Error occurred please try again");
      });
  };

  const countChange = (count, type) => {
    if (type === "linkExpiry") {
      setLinkExpiryTime(count);
    }

    if (type === "waitDuration") {
      setWaitDuration(count);
    }

    if (type === "duration") {
      setInterviewDuration(count);
    }
  };

  const switchButton = (data, type) => {
    if (type === "faceDetection") {
      setFaceDetection(data);
    }

    if (type === "tabSwitchDetection") {
      setTabSwitchDetection(data);
    }
  };

  const saveDataIntrviewSettings = () => {
    setLoading(true);
    const obj = {
      link_expire_duration: linkExpiryTime,
      max_duration_per_qn: interviewDuration,
      max_wait_duration_after_qn_read: waitDuration,
      face_detection: faceDetection,
      fraud_detection: tabSwichDetection,
      interview_id: tenantConfigId,
    };

    fetch(`${API}/admin/tenant/interview-setting/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          toast.success("Interview Settings Updated Successfully");
        } else {
         
          if (res.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(res.error.msg);
          }
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        toast.error("Error occurred please try again");
      });
  };

  return (
    <div className="parentDiv">
      {initialLoading ? (
        <>
          <div className="loader-div-job">
            <ProgressBarComponent />
          </div>
        </>
      ) : (
        <>
          <div
            className="row"
            style={{ alignItems: "center", marginTop: "10px" }}
          >
            <div className="col-3">
              <p>Interview link expiry duration (in days)</p>
            </div>
            <div className="col-5">
              <DurationButton
                countChange={countChange}
                type="linkExpiry"
                value={linkExpiryTime}
              />
            </div>
          </div>
          <div
            className="row"
            style={{ alignItems: "center", marginTop: "10px" }}
          >
            <div className="col-3">
              <p>Max interview duration (in minutes)</p>
            </div>
            <div className="col-5">
              <DurationButton
                countChange={countChange}
                type="duration"
                value={interviewDuration}
              />
            </div>
          </div>

          <div
            className="row"
            style={{ alignItems: "center", marginTop: "10px" }}
          >
            <div className="col-3">
              <p>Max wait time after reading question (in  seconds)</p>
            </div>
            <div className="col-5">
              <DurationButton
                countChange={countChange}
                type="waitDuration"
                value={waitDuration}
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-3">
              <p>Face detection</p>
            </div>
            <div className="col-5">
              <SwitchComponent
                switchButton={switchButton}
                type={"faceDetection"}
                value={faceDetection}
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-3">
              <p>Tab switch detection</p>
            </div>
            <div className="col-5">
              <SwitchComponent
                switchButton={switchButton}
                type={"tabSwitchDetection"}
                value={tabSwichDetection}
              />
            </div>
          </div>



          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-4 label">
              {" "}
              <button onClick={saveDataIntrviewSettings} disabled={loading} className="saveButton">
                {loading ? 'Saving...' : "Save"}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default InterviewSettings;
