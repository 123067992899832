import React, { useEffect } from "react";
import SwitchComponent from "../../Reusable/SwitchComponent";
import { useState } from "react";
import edit from "../../../../src/assets/edit.svg";
import { API } from "../../../global";
import GroupSettings from "./GroupSettings";
import toast from "react-hot-toast";
import ProgressBarComponent from "../../Reusable/ProgressBarComponent";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router";

const AuthenticationSetting = () => {

  const navigate = useNavigate()

  const [entraIdCheked, setEntraIdCheked] = useState(false);
  const [basicAuth, setBasciAuth] = useState(false);
  const [ediDisable, seteditDisable] = useState(true);
  const [adAuthData, setAdAuthData] = useState(null);
  const [adGroupdata, setAdgrupdata] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line
  }, []);

  const fetchData = () => {
    fetch(`${API}/admin/tenant/auth-setting`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setInitialLoading(false);
          setAdAuthData(res.result.authData);
          setEntraIdCheked(res?.result?.authData?.azure_ad_auth_enabled);
          setBasciAuth(res?.result?.authData?.basic_auth_enabled);
          setAdGrupList(res.result.adGroupData);
        }else{
          
          if (res.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(res.error.msg);
          }
        }
      })
      .catch(() => {
        setInitialLoading(false);
      });
  };

  const switchentraId = (data, type) => {
    if (type === "entraId") {
      seteditDisable(true);
      setEntraIdCheked(data);
      if (adAuthData) adAuthData.azure_ad_auth_enabled = data;
      enableSingleSignOn(data);
    }

    if (type === "basicAuth") {
      adAuthData.basic_auth_enabled = data;
      enableBasicAuth(data);
      setBasciAuth(data);
    }
  };

  const enableEdit = () => {
    seteditDisable(!ediDisable);
  };

  const setAdGrupList = (adData) => {
    const filteredGroupData = adData.sort((a, b) =>
      a.description.localeCompare(b.description)
    );
    setAdgrupdata(filteredGroupData);
  };

  const saveGroupData = () => {
    const obj = {
      auth_id: adAuthData._id,
      azure_tenant_id: adAuthData.azure_ad_credentials.azure_tenant_id,
      azure_aud_id: adAuthData.azure_ad_credentials.azure_aud_id,
      azure_application_id:
        adAuthData.azure_ad_credentials.azure_application_id,
      azure_scope: adAuthData.azure_ad_credentials.azure_scope,
      client_secrete: adAuthData.azure_ad_credentials.client_secrete,
    };
    setLoading(true);
    fetch(`${API}/admin/tenant/auth-setting/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setLoading(false);
          toast.success("Azure credentials updated");
          seteditDisable(!ediDisable);
        } else {
         
          if (res.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(res.error.msg);
          }
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error("Error occurred please try again");
      });
  };

  const enableSingleSignOn = (data) => {
    const obj = {
      auth_id: adAuthData?._id,
      type: "singleSignOn",
      auth_data: data,
    };
    let msg = "";
    if (data) {
      msg = "Microsoft Entra ID Enabled";
    } else {
      msg = "Microsoft Entra ID Disabled";
    }
    enable(obj, msg);
  };

  const enableBasicAuth = (data) => {
    const obj = {
      auth_id: adAuthData._id,
      type: "basicAuth",
      auth_data: data,
    };
    let msg = "";
    if (data) {
      msg = " Basic Auth Enabled";
    } else {
      msg = " Basic Auth Disabled";
    }
    enable(obj, msg);
  };

  const enable = (obj, msg) => {
    fetch(`${API}/admin/tenant/auth-setting/enableAuth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          toast.success(msg);
        } else {
          setEntraIdCheked(entraIdCheked);
          setBasciAuth(basicAuth);
          if (res.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(res.error.msg);
          }
        }
      })
      .catch(() => {
        toast.error("Error occurred please try again");
      });
  };

  const handleChange = (e) => {
    adAuthData.azure_ad_credentials[e.target.id] = e.target.value;
  };

  return (
    <>
      <Tooltip
        id="authentication-setting-tooltip"
        variant="light"
        className="candidate-tooltip"
        border="1px solid #7C7B7B"
      />
      {initialLoading ? (
        <div className="loader-div-job">
          <ProgressBarComponent />
        </div>
      ) : (
        <div>
          <div style={{ padding: "20px" }}>
            <div>
              <h4>Single Sign On</h4>
              <p style={{ fontSize: "14px", fontWeight: "normal" }}>
                Enable users within your company to log in using Microsoft Entra
                ID.
              </p>
            </div>

            <div>
              <div className="row grupDiv">
                <div className="col-sm-2">
                  <p className="textLabel">Microsoft Entra ID</p>
                </div>
                <div className="col-sm-6 ">
                  <div className="headerDiv">
                    <SwitchComponent
                      switchButton={switchentraId}
                      type={"entraId"}
                      value={entraIdCheked}
                    />

                    {entraIdCheked && (
                      <>
                        {ediDisable ? (
                          <button
                            style={{ border: "none", background: "none" }}
                            onClick={enableEdit}
                          >
                            <img
                              style={{
                                width: "16px",
                                height: "16px",
                                cursor: "pointer",
                              }}
                              src={edit}
                              alt=""
                              data-tooltip-id="authentication-setting-tooltip"
                              data-tooltip-content="Edit"
                              data-tooltip-place="top"
                            />
                          </button>
                        ) : (
                          <div className="d-flex gap-3">
                            <button
                              className="auth-save-button"
                              onClick={saveGroupData}
                            >
                              {loading ? "Saving..." : "Save"}
                            </button>
                            <button
                              className="auth-cancel-button"
                              onClick={enableEdit}
                            >
                              Cancel
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {entraIdCheked && (
                <>
                  <div>
                    <div className="row grupDiv">
                      <div className="col-sm-2">
                        <p className="subTitleText">Application ID</p>
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          style={{
                            width: "400px",
                            height: "40px",
                            borderRadius: "4px",
                          }}
                          id="azure_application_id"
                          onChange={handleChange}
                          defaultValue={
                            adAuthData?.azure_ad_credentials
                              ?.azure_application_id
                          }
                          disabled={ediDisable}
                        />
                      </div>
                    </div>
                    <div className="row grupDiv">
                      <div className="col-sm-2">
                        <p className="subTitleText">Client Secret Key</p>
                      </div>
                      <div className="col-sm-6">
                        <input
                          disabled={ediDisable}
                          id="client_secrete"
                          type="text"
                          onChange={handleChange}
                          defaultValue={
                            adAuthData?.azure_ad_credentials?.client_secrete
                          }
                          style={{
                            width: "400px",
                            height: "40px",
                            borderRadius: "4px",
                            position: "relative",
                          }}
                        />
                        {/* <span style={{position:'absolute'}}>
                <img src={eye} alt="" />
              </span> */}
                      </div>
                    </div>
                    <div className="row grupDiv">
                      <div className="col-sm-2">
                        <p className="subTitleText">Azure AUD ID</p>
                      </div>
                      <div className="col-sm-6">
                        <input
                          onChange={handleChange}
                          id="azure_aud_id"
                          defaultValue={
                            adAuthData?.azure_ad_credentials?.azure_aud_id
                          }
                          type="text"
                          style={{
                            width: "400px",
                            height: "40px",
                            borderRadius: "4px",
                          }}
                          disabled={ediDisable}
                        />
                      </div>
                    </div>
                    <div className="row grupDiv">
                      <div className="col-sm-2">
                        <p className="subTitleText">Azure Tenant ID</p>
                      </div>
                      <div className="col-sm-6">
                        <input
                          onChange={handleChange}
                          id="azure_tenant_id"
                          type="text"
                          defaultValue={
                            adAuthData?.azure_ad_credentials?.azure_tenant_id
                          }
                          style={{
                            width: "400px",
                            height: "40px",
                            borderRadius: "4px",
                          }}
                          disabled={ediDisable}
                        />
                      </div>
                    </div>
                  </div>
                  <GroupSettings adGroupdata={adGroupdata} />
                </>
              )}

              <hr className="solid"></hr>
              <div className="row grupDiv">
                <div className="col-sm-2">
                  <p className="authenticationText">Basic Authentication</p>
                </div>
                <div className="col-sm-6">
                  <SwitchComponent
                    switchButton={switchentraId}
                    type={"basicAuth"}
                    value={basicAuth}
                  />
                </div>
              </div>
              <div>
                <p className="subTitleText">
                  Enable users within your company to log in using user name and
                  password.
                </p>
              </div>
              <hr className="solid"></hr>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AuthenticationSetting;
