import React from "react";
import arrowImage from "../../../assets/icons/ArrowIcon.svg";
import addWhite from "../../../assets/add-white.svg";
// import genQues from "../../../assets/genQuestion.svg";
// import genQueshover from '../../../assets/AI Icon - White - Hover.svg';
import { useNavigate } from "react-router";

const AddQuestionHeader = ({
  btnenabled,
  setShowModal,
  title,
  setModalTitle
}) => {
  const navigate = useNavigate();
  // const [isHovered, setIsHovered] = useState(false);
  // const handleHover = () => {
  //   setIsHovered(!isHovered);
  // };

  const handleAddButton = () => {
    setShowModal(true);
    setModalTitle("Add");
  };

  return (
    <div className="add-ques-header">
      <button className="icon-div" onClick={() => navigate(-1)}>
        <img src={arrowImage} alt="" className="backImage" />
      </button>
      <div className="add-title-container">
        <div className="table-label text-center">
          <h5>{title} questionnaire</h5>
        </div>
        {btnenabled ? (
          <div className="d-flex gap-2">
            <button
              className="add-question-btn px-2"
              onClick={() => handleAddButton()}
            >
              <img src={addWhite} alt="" className="px-2" />
              Add Question
            </button>
            {/* <button className="generate-question-btn me-2" onMouseEnter={handleHover}
            onMouseLeave={handleHover}>
            <img src={isHovered ? genQues:genQueshover} alt='' className="px-2"   />
              Generate Questions
            </button> */}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default AddQuestionHeader;
