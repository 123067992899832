import React from "react";
// import ignitho from '../../../assets/Ignitho - White Logo - Transparent BG.png';
import logout from "../../../assets/logout.svg";
import talentuo from "../../../assets/Talentou-Logo-nav.svg";
import profile from "../../../assets/candidate.svg";
import "./Nav.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { API, commonUrl } from "../../../global";
import { Tooltip } from "react-tooltip";
import toast from "react-hot-toast";
import { getTheme } from "../../../Styles/ThemeChange";
import search from "../../../assets/Search-white.svg";
import searchpurple from "../../../assets/Search.svg";
import { TailSpin } from "react-loader-spinner";
import { getThemeColor } from "../../Functions/commonFunctions";
import help from "../../../assets/help-icon.svg";

// import { useHistory } from 'react-router-dom';

const Nav = ({ type, showRole, handleTour }) => {
  const navigate = useNavigate();

  // const history = useHistory();

  const [userName, setUserName] = useState("");
  const [role, setRole] = useState("");
  const [show, setShow] = useState(false);
  // const [userRoles, setUserRoles] = useState([]);
  const [userRoleList, setUserRoleList] = useState(null);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedRoleName, setSelectedRoleName] = useState("");
  const [loading, setLoading] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isSugguestionOpen, setIsSugguestionOpen] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [jobOrders, setJobOrders] = useState([]);
  const [completedInterview, setCompletedInterview] = useState([]);
  const [noResultFound, setNoResultFound] = useState(false);
  const [closeSearch, setCloseSearch] = useState(false);
  let record_token = sessionStorage.getItem("record_token");

  useEffect(() => {
    if (!record_token) {
      setInitialData();
    }

    // eslint-disable-next-line
  }, []);

  const setInitialData = () => {
    setUserName(localStorage.getItem("user-name"));
    const userRoles = localStorage.getItem("user_role_keys");
    const currentRole = localStorage.getItem("current_role_key");
    const uniqueKey = new Date().getTime();
    const imageUrl = localStorage.getItem("logoUrl")
      ? `${localStorage.getItem("logoUrl")}?${uniqueKey}`
      : "";
    setLogoUrl(imageUrl);

    // eslint-disable-next-line
    JSON.parse(userRoles)?.map((data) => {
      if (data?.role_key === currentRole) {
        setRole(data.name);
        // console.log("data.name",data.name)
        setSelectedRoleName(data.name);
      }
    });
    setUserRoleList(JSON.parse(userRoles));
    setSelectedRole(currentRole);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  // let Roleid = selectedRole === undefined ? localStorage.getItem('role_id') -1 : selectedRole
  const ChangeRole = () => {
    setLoading(true);
    // console.log("selectedRole",selectedRole)
    fetch(`${API}/admin/profile/settings/update-current-role`, {
      method: "POST",
      body: JSON.stringify({
        role_key: selectedRole,
      }),
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((value) => value.json())
      .then((result) => {
        setShow(false);
        setLoading(false);
        if (result.success) {
          toast.success("Role Changed Successfully");
          localStorage.setItem("role-name", selectedRoleName);
          localStorage.setItem(
            "current_role_key",
            result.result.current_role_key
          );
          localStorage.setItem("role_id", selectedRole);
          if (selectedRole === "talentou_super_admin") {
            navigate("/superAdmin-dashboard");
          } else {
            navigate("/admin-dashboard");
          }

          setInitialData();
        } else {
          if (result.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(result.error.msg);
          }
        }
      });
  };

  const chooseRole = (e, n) => {
    setSelectedRole(e);
    setSelectedRoleName(n);
  };

  const handleLogout = () => {
    fetch(`${API}/admin/auth/logout`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((value) => value.json())
      .then((val) => {
        if (val.success) {
          if (window.location.origin === commonUrl) {
            navigate("/");
          } else {
            navigate("/SignIn");
          }

          localStorage.clear();
          sessionStorage.clear();
          getTheme();
        } else {
          if (val.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(val.error.msg);
          }
        }
      });
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleBox = () => {
    // console.log("123")
    setIsOpen(true);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      // Call your function here
      handleSearchImg();
    }
  };

  const handleSearchBox = (e) => {
    setCloseSearch(true);
    setSearchValue(e.target.value);
    setIsSugguestionOpen(true);
  };

  const handleCloseSearch = () => {
    setSearchValue("");
    setIsSugguestionOpen(false);
    setIsOpen(false);
    setNoResultFound(false);
    setCandidates([]);
    setJobOrders([]);
    setCompletedInterview([]);
  };

  const handleSearchImg = () => {
    setSearchLoading(true);

    if (searchValue === "") {
      setIsOpen(false);
      setSearchLoading(false);
    } else {
      fetch(`${API}/admin/global/search`, {
        method: "POST",
        body: JSON.stringify({
          search_value: searchValue,
        }),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      })
        .then((value) => value.json())
        .then((res) => {
          if (res.success) {
            console.log(res.result, "res.result");
            setCandidates(res?.result?.candidates);
            setJobOrders(res?.result?.job_orders);
            setCompletedInterview(res?.result?.completed_interview);
            setSearchLoading(false);

            if (
              res?.result?.candidates.length === 0 &&
              res?.result?.job_orders.length === 0 &&
              res?.result?.completed_interview.length === 0
            ) {
              setNoResultFound(true);
            }
          } else {
            setSearchLoading(false);

            if (res.error.code === 421) {
              navigate("/error");
            } else {
              toast.error(res.error.msg);
            }
          }
        });
    }
    setNoResultFound(false);
    setCandidates([]);
    setJobOrders([]);
    setCompletedInterview([]);
  };

  const handleNavigate = (jobDetails) => {
    navigate(`/interview/candidatelist/${jobDetails._id}`);
    setSearchValue(jobDetails.job_role);
    // setLoadSearch(true);
    // console.log(LoadSearch,"setLoadSearch")
    setSearchValue("");
    setIsSugguestionOpen(false);
    setIsOpen(false);
    setNoResultFound(false);
    setCandidates([]);
    setJobOrders([]);
    setCompletedInterview([]);
  };

  return (
    <div>
      <Tooltip
        border="1px solid #7C7B7B"
        id="nav-tooltip"
        variant="light"
        className="logout-tooltip"
      />
      <Modal show={show} onHide={handleClose} className="nav-model">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Choose Role
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {userRoleList?.map((roleData, i) => (
            <div key={i} className="">
              <label className="ms-2" htmlFor={roleData.name}>
                {" "}
                <input
                  type="radio"
                  id={roleData.name}
                  value={selectedRole}
                  checked={roleData.role_key === selectedRole}
                  onChange={(e) => chooseRole(roleData.role_key, roleData.name)}
                />{" "}
                {roleData.name}
              </label>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {userRoleList?.length > 1 && (
            <Button className="mod-btn-color" onClick={() => ChangeRole()}>
              {loading ? "Loading..." : "Change"}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      <nav className="navbar nav-bg px-3">
        <div className="nav-logo-container">
          {logoUrl ? (
            <img
              src={logoUrl}
              alt="logo"
              style={{ height: "50px", objectFit: "cover" }}
            />
          ) : (
            <img
              src={talentuo}
              alt="talentio logo"
              style={{ width: "80px" }}
              className="ml-4 img-fluid"
            />
          )}
        </div>

        {!showRole && (
          <div className=" navbar-icon-container">
            <div className="search-box">
              {isOpen && (
                <form className="search-container">
                  <input
                    type="text"
                    className={`box ${isOpen ? "open px-2" : ""}`}
                    value={searchValue}
                    placeholder="Start typing to search"
                    onChange={(e) => handleSearchBox(e)}
                    onKeyDown={handleKeyDown}
                  />
                  {searchLoading ? (
                    <span className="mt-2" style={{ marginLeft: "-30px" }}>
                      <TailSpin
                        visible={true}
                        height="20"
                        width="20"
                        color={getThemeColor().primaryColor}
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </span>
                  ) : (
                    <>
                      {closeSearch ? (
                        <button
                          className="search-btn-2"
                          onClick={() => handleCloseSearch()}
                        >
                          X
                        </button>
                      ) : (
                        <img
                          src={searchpurple}
                          alt=""
                          className="search-btn-1"
                          onClick={() => handleSearchImg()}
                          style={{ cursor: "pointer" }}
                          width={18}
                          height={18}
                        />
                      )}
                    </>
                  )}
                  {isSugguestionOpen && (
                    <div className="suggestions-container">
                      <div className="suggestion-item">
                        <span>
                          <img
                            src={searchpurple}
                            alt=""
                            className=""
                            onClick={() => handleSearchImg()}
                            style={{ cursor: "pointer" }}
                          />{" "}
                        </span>
                        <span className="px-2">{searchValue}</span>{" "}
                        <b>Press enter to view all results</b>
                      </div>
                      {noResultFound ? (
                        <h6 className="suggestion-item">No result found</h6>
                      ) : (
                        <>
                          {jobOrders.length !== 0 && (
                            <div>
                              <div className="px-3 sub-nav-heading">
                                <b>Job-Orders</b>
                              </div>
                              {jobOrders.map((j, i) => (
                                <div
                                  key={i}
                                  className="suggestion-item"
                                  onClick={() => handleNavigate(j)}
                                >
                                  {j.job_role}
                                </div>
                              ))}
                            </div>
                          )}
                          {candidates.length !== 0 && (
                            <div>
                              <div className="px-3 sub-nav-heading">
                                <b>Candidates</b>
                              </div>
                              {candidates?.map((c, i) => (
                                <div
                                  key={i}
                                  className="suggestion-item"
                                  onClick={() =>
                                    navigate(
                                      `/interview/candidatelist/${c.job_order_id[0]}`
                                    )
                                  }
                                >
                                  {c.name}
                                </div>
                              ))}
                            </div>
                          )}
                          {completedInterview.length !== 0 && (
                            <div>
                              <div className="px-3  sub-nav-heading">
                                <b>Completed Interviews</b>
                              </div>
                              {completedInterview?.map((c, i) => (
                                <div
                                  key={i}
                                  className="suggestion-item"
                                  onClick={() =>
                                    navigate(
                                      `/interview/view-recordings/${c.interview_id}`
                                    )
                                  }
                                >
                                  {c.candidate_id.name} -{" "}
                                  {c.job_order_id.job_role}
                                </div>
                              ))}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </form>
              )}

              {type !== "view" ? (
                <>
                  {!isOpen && (
                    <button className="d-flex justify-content-end toggle-bth w-100 px-1">
                      <img
                        src={search}
                        alt=""
                        width={18}
                        height={18}
                        onClick={toggleBox}
                        className="step-6"
                        data-tooltip-id="nav-tooltip"
                        data-tooltip-content="Search"
                        data-tooltip-place="bottom"
                      />
                    </button>
                  )}
                </>
              ) : (
                ""
              )}
            </div>
            <div className="d-flex">
              {type !== "view" ? (
                <>
                  {!record_token && (
                    <img
                      src={profile}
                      alt="Profile"
                      className="profile-image px-2 img-fluid"
                      width="50px"
                      heigth="50px"
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </>
              ) : (
                ""
              )}

              {!record_token && (
                <div
                  className="d-inline-block w-100 step-7"
                  style={{ cursor: "pointer" }}
                >
                  <button
                    style={{ background: "none", border: "none" }}
                    onClick={handleShow}
                  >
                    <div
                      className="profile-name"
                      style={{
                        color: "#fff",
                        fontWeight: "bold",
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                    >
                      {userName}
                    </div>
                    <div
                      className="role-name"
                      style={{
                        color: "#fff",
                        cursor: "pointer",
                        fontSize: "12px",
                      }}
                    >
                      {role}
                    </div>
                  </button>
                </div>
              )}

              {type !== "view" ? (
                <>
                  {localStorage.getItem("current_role_key") !==
                  "talentou_super_admin" ? (
                    <button
                      className="help-button-navbar px-2 "
                      onClick={() => handleTour()}
                    >
                      <img
                        src={help}
                        className="step-8"
                        alt="Help"
                        style={{ cursor: "pointer", width: "20px" }}
                        data-tooltip-id="nav-tooltip"
                        data-tooltip-content="Take a tour"
                        data-tooltip-place="bottom"
                      />
                    </button>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}

              {type !== "view" ? (
                <button
                  className="px-2"
                  style={{ border: "none", background: "none" }}
                  onClick={handleLogout}
                >
                  <img
                    src={logout}
                    alt=""
                    style={{ width: "18px", cursor: "pointer" }}
                    data-tooltip-id="nav-tooltip"
                    data-tooltip-content="Logout"
                    data-tooltip-place="bottom"
                  />
                </button>
              ) : (
                ""
              )}
            </div>
            <div className="talent-logo ">
              {logoUrl && (
                <img
                  src={talentuo}
                  alt="talentio logo"
                  style={{ width: "80px" }}
                  className="ml-4 img-fluid"
                />
              )}

              <div className="horizontal-line"></div>
            </div>
          </div>
        )}
      </nav>
    </div>
  );
};

export default Nav;
