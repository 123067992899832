import React, { useEffect, useState } from "react";
import Nav from "../Reusable/Navbar/Nav";
import SideMenu from "../Reusable/SideMenu/SideMenu";
import { Col, Container, Row } from "react-bootstrap";
// import settings from "../../assets/dash-settings.svg";
import "./KanbanBoard.css";
import CanDropdown from "../Reusable/CanDropdown";
import Footer from "../Footer/Footer";
import { API } from "../../global";
import ProgressBarComponent from "../Reusable/ProgressBarComponent";
import { Tooltip } from "react-tooltip";
import { toast } from "react-hot-toast";
import { CiMenuKebab } from "react-icons/ci";
import job from "../../assets/job-grey.svg";
// import drag from "../../assets/drag.svg";
import { useNavigate } from "react-router";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Modal from "react-responsive-modal";
import { TailSpin } from "react-loader-spinner";
import { getThemeColor } from "../Functions/commonFunctions";
import SearchInput from "../Reusable/SearchInput";

const customStyles = {
  color: "white",
  fontSize: "10px",
  borderRadius: "15px",
  padding: "3px 4px",
  border: "none",
};

export const AtsStatusHeader = ({ title, count, i }) => {
  return (
    <div className={`kanban-board-status-${i} rounded kanban-sts`}>
      <div className="d-flex justify-content-between px-2 py-2">
        <div className="">{title}</div>
        <div className="d-flex align-items-center">
          <div
            className={` kanban-board-data-count-${i} badge`}
            style={customStyles}
          >
            {count}
          </div>
          <CiMenuKebab />
        </div>
      </div>
    </div>
  );
};

const reportArray = [
  { value: "Lifetime", label: "Lifetime", key: "lifetime" },
  { value: "Today", label: "Today", key: "today" },
  { value: "Yesterday", label: "Yesterday", key: "yesterday" },
  { value: "Last 7 days", label: "Last 7 days", key: "last-7-days" },
  { value: "Last 30 days", label: "Last 30 days", key: "last-30-days" },
];

const KanbanBoard = () => {
  // const [isHovered, setIsHovered] = useState(false);
  const [columnKeyName, setColumnKeyName] = useState([]);
  const [atsDataLoading, setAtsDataLoading] = useState(false);
  const [atsStatus, setAtsStatus] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [jobList, setJobList] = useState([]);
  // const [dragDisabled, setDragDisabled] = useState(false);
  const [showPopup, setShowPopUp] = useState(false);
  const [dragEnable, setDragEnable] = useState(false);
  const [intervalData, setIntervalData] = useState("");
  const [intervalKey, setIntervalKey] = useState("");
  const [jobId, setJobId] = useState("");
  const [jobOrderId, setJobOrderId] = useState("");
  const [searchData, setSearchData] = useState("");
  const [hoveredIndexes, setHoveredIndexes] = useState(
    Array(atsStatus?.length).fill(null)
  ); // State to track hovered item index for each column

  // const isButtonDisabled = true;

  const handleHoveredIndex = (index, columnIndex) => {
    const newHoveredIndexes = [...hoveredIndexes];
    newHoveredIndexes[columnIndex] = index;
    setHoveredIndexes(newHoveredIndexes);
  };

  const atsName = localStorage.getItem("ats_name");
  // const dragDropDisable = localStorage.getItem("dragDisable");

  useEffect(() => {
    getJobs();
    getKeyNames();
    // if (dragDropDisable === "true") {
    //   setDragDisabled(true);
    // }

    if (atsName !== "talentou_atsstatus") {
      //change to true after api call
      setDragEnable(false);
    }
    //eslint-disable-next-line
  }, []);

  const getKeyNames = () => {
    setLoading(true);
    fetch(`${API}/admin/kanban-board/list-columns`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((val) => {
        if (val.success === true) {
          setLoading(false);
          setColumnKeyName(val.result);
          getCandidateByKey(val.result);
        } else {
          setLoading(false);
        }
      });
  };

  const getCandidateByKey = (columnData, jobId, interval, candidateName) => {
    setAtsDataLoading(true);
    let totalData = [];

    let kanbanObj = {
      job_order_id: jobId ? jobId : null,
      interval: interval ? interval : null,
      candidate_name: candidateName ? candidateName : null,
    };

    fetch(`${API}/admin/kanban-board/list-candidates`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(kanbanObj),
    })
      .then((res) => res.json())
      .then((val) => {
        if (val.success) {
          // eslint-disable-next-line
          columnData.map((item) => {
            // eslint-disable-next-line
            val.result.map((data) => {
              if (item.name === data.name) {
                const obj = {
                  id: item._id,
                  title: item.name,
                  columnKey: item.kanban_column_key,
                  data: data.candidates,
                };
                totalData.push(obj);
              }
            });
          });
        }
        setAtsDataLoading(false);
        setAtsStatus(totalData);
      })
      .catch((error) => {
        console.error("Error fetching candidates:", error);
        setAtsDataLoading(false); // Set loading to false after each fetch call rejects
      });
  };

  const getJobs = () => {
    setLoading(true);
    // setRun(false);
    fetch(`${API}/admin/job-order/list`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((val) => {
        let listJOb = [];
        if (val.success === true) {
          setLoading(false);
          // eslint-disable-next-line
          val.result.map((item) => {
            const obj = {
              value: item.job_role,
              label: item.job_role,
              id: item._id,
            };
            listJOb.push(obj);
          });
          setJobList(listJOb);
        } else {
          if (val.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(`${val.error.msg}`);
          }
          setLoading(false);
        }
      });
  };

  const handleTour = () => {
    localStorage.setItem("tourGuid", true);
    navigate("/admin-dashboard");
  };

  const handleDragDrop = (results) => {
    if (dragEnable) {
      setShowPopUp(true);
    } else {
      const { source, destination, type } = results;
      if (!destination) return;

      if (
        source.droppableId === destination.droppableId &&
        source.index === destination.index
      )
        return;
      if (type === "group") {
        const atsSourceIndex = atsStatus.findIndex(
          (ats) => ats.id === source.droppableId
        );

        const atsDestinationIndex = atsStatus.findIndex(
          (ats) => ats.id === destination.droppableId
        );
        const newSourceATS_Status = [...atsStatus[atsSourceIndex]?.data];
        const newDestinationATS_Status =
          source.droppableId !== destination.droppableId
            ? [...atsStatus[atsDestinationIndex].data]
            : newSourceATS_Status;

        const [deletedItem] = newSourceATS_Status.splice(source.index, 1);
        newDestinationATS_Status.splice(destination.index, 0, deletedItem);

        const newATS_Status = [...atsStatus];
        newATS_Status[atsSourceIndex] = {
          ...atsStatus[atsSourceIndex],
          data: newSourceATS_Status,
        };

        newATS_Status[atsDestinationIndex] = {
          ...atsStatus[atsDestinationIndex],
          data: newDestinationATS_Status,
        };

        setAtsStatus(newATS_Status);
        updateAtsStatus(results);
      }
    }
  };

  const updateAtsStatus = (results) => {
    const { source, destination, draggableId } = results;
    const atsSourceIndex = atsStatus.findIndex(
      (ats) => ats.id === source.droppableId
    );

    const atsDestinationIndex = atsStatus.findIndex(
      (ats) => ats.id === destination.droppableId
    );
    let candidateId = "";
    let jobId = "";

    const atsDataStatus = atsStatus[atsSourceIndex].data;
    const dragId = draggableId.split("#")[0];
    // eslint-disable-next-line
    atsDataStatus.map((data) => {
      if (data._id === dragId) {
        candidateId = data.candidate_id;
        jobId = data.job_order_id;
      }
    });

    const obj = {
      candidate_id: candidateId,
      job_order_id: jobId,
      ats_status: atsStatus[atsDestinationIndex].columnKey,
    };

    fetch(`${API}/admin/candidate/update-ats-status`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((val) => {
        if (val.success === true) {
        } else {
          if (val.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(`${val.error.msg}`);
          }
          setLoading(false);
        }
      });
  };

  const handleClose = () => {
    setShowPopUp(!showPopup);
    // setDragDisabled(true);
  };

  const handleEnableDrag = () => {
    setDragEnable(false);
    setShowPopUp(!showPopup);
  };

  const upgradePlan = () => {
    setShowPopUp(!showPopup);
  };

  const handleSelect = (type, data) => {
    if (type === "interval") {
      const intervalData = reportArray.filter((item) => item.label === data);
      setIntervalData(intervalData[0].label);
      setIntervalKey(intervalData[0].key);
      getCandidateByKey(
        columnKeyName,
        jobOrderId,
        intervalData[0].key,
        searchData
      );
    }

    if (type === "job") {
      if (data === "All Job Orders") {
        setJobId("All Job Orders");
        if (intervalKey === "lifetime") {
          setIntervalData("Last 7 days");
          setIntervalKey("last-7-days");
        }
        getCandidateByKey(columnKeyName, null, "last-7-days", searchData);
      } else {
        const jobData = jobList.find((item) => item.value === data);
        getCandidateByKey(columnKeyName, jobData.id, intervalKey, searchData);
        setJobOrderId(jobData.id);
        setJobId(data);
      }
    }
  };

  useEffect(() => {
    if (searchData === "") {
      getCandidateByKey(columnKeyName, jobOrderId, intervalKey, searchData);
    }

    //eslint-disable-next-line
  }, [searchData]);

  const handleSearch = () => {
    getCandidateByKey(columnKeyName, jobOrderId, intervalKey, searchData);
  };

  return (
    <div>
      <Tooltip
        id="kanban-board-tooltip"
        variant="light"
        className="candidate-tooltip"
        border="1px solid #7C7B7B"
      />
      <Nav handleTour={handleTour} />
      <Container fluid className="d-flex m-0 p-0 kanban-main-content">
        <div className="sidemenu-section">
          <SideMenu menuIndex={8} />
        </div>
        {loading ? (
          <>
            <div className="loader-div-job">
              <ProgressBarComponent />
            </div>
          </>
        ) : (
          <div className="w-100">
            <Container fluid className="kanban-board-main-container">
              <Row>
                <Row className="p-0 m-0">
                  <Col className="kanban-title-container mx-2 mt-3 mb-2">
                    <div className="kanban-board-title mr-1">Kanban Board</div>
                    {/* <img
                      src={settings}
                      alt="settings"
                      width="18px"
                      className={
                        isHovered ? "me-2 rotate-img" : "ms-2 disabled-img"
                      }
                    /> */}
                    <div className="kanban-filter-div">
                      <div>
                        <CanDropdown
                          width="270px"
                          defaultText="All Job Orders"
                          selectedValue={jobId}
                          handleSelectedValue={(e) => handleSelect("job", e)}
                          DropdownArr={jobList}
                          backgroundColor={getThemeColor()?.primaryShade4}
                          color="Black"
                          border={getThemeColor()?.primaryShade2}
                        />
                      </div>
                      <div>
                        <CanDropdown
                          width="270px"
                          defaultText="Last 7 days"
                          selectedValue={intervalData}
                          handleSelectedValue={(e) =>
                            handleSelect("interval", e)
                          }
                          DropdownArr={
                            jobId === "All Job Orders" || jobId === ""
                              ? reportArray.filter(
                                  (item) =>
                                    item.value !== "Lifetime" &&
                                    item.value !== "Last 30 days"
                                )
                              : reportArray
                          }
                          backgroundColor={getThemeColor()?.primaryShade4}
                          color="Black"
                          border={getThemeColor()?.primaryShade2}
                          disableDefault={true}
                        />
                      </div>
                      <div style={{ margin: "2px" }}>
                        <SearchInput
                          onFilter={(e) => setSearchData(e.target.value)}
                          onSearch={() => handleSearch()}
                          filterText={searchData}
                          enableOnClick={true}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* <Row className="p-0 m-0">
                  <Col className="kanban-board-header p-3 mx-3">
                    <div>
                      <div>
                        <b>Select Job Order</b>
                      </div>
                      <div>
                        <CanDropdown
                          width="270px"
                          defaultText="All Job Orders"
                          selectedValue={jobId}
                          handleSelectedValue={(e) => handleSelect("job", e)}
                          DropdownArr={jobList}
                          backgroundColor="#F7F7F7"
                          color="Black"
                          border="#E9E9E9"
                        />
                      </div>
                    </div>
                    <div>
                      <div>
                        <CanDropdown
                          width="270px"
                          defaultText="Last 7 days"
                          selectedValue={intervalData}
                          handleSelectedValue={(e) =>
                            handleSelect("interval", e)
                          }
                          DropdownArr={
                            jobId === "All Job Orders" || jobId === ""
                              ? reportArray.filter(
                                  (item) =>
                                    item.value !== "Lifetime" &&
                                    item.value !== "Last 30 days"
                                )
                              : reportArray
                          }
                          backgroundColor="#F7F7F7"
                          color="Black"
                          border="#E9E9E9"
                          disableDefault={true}
                        />
                      </div>
                    </div>
                    <div style={{ margin: "2px" }}>
                      <SearchInput
                        onFilter={(e) => setSearchData(e.target.value)}
                        onSearch={() => handleSearch()}
                        filterText={searchData}
                        enableOnClick={true}
                      />
                    </div>
                  </Col>
                </Row> */}
                <div className="p-0 m-0 mt-4 px-2 ats-status-main-content">
                  <DragDropContext onDragEnd={handleDragDrop} autoScroll={true}>
                    {columnKeyName.map((status, columnIndex) => (
                      <div className="p-0 mx-2  ats-status-sub-container">
                        <Droppable droppableId={status._id} type="group">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              <AtsStatusHeader
                                i={columnIndex}
                                title={status.name}
                                count={
                                  atsStatus[columnIndex]?.data?.length > 0
                                    ? atsStatus[columnIndex]?.data?.length
                                    : 0
                                }
                                id={status.id}
                              />

                              <>
                                {atsDataLoading ? (
                                  <div className="d-flex justify-content-center m-1">
                                    <TailSpin
                                      visible={true}
                                      height="20"
                                      width="20"
                                      color={getThemeColor().primaryColor}
                                      ariaLabel="tail-spin-loading"
                                      radius="1"
                                      wrapperStyle={{}}
                                      wrapperClass=""
                                    />
                                  </div>
                                ) : (
                                  <>
                                    <div
                                    
                                      style={{
                                        overflowY: "auto",
                                        maxHeight: "75vh", // Adjust height as needed
                                      }}
                                    >
                                    {atsStatus[columnIndex]?.data.map(
                                      (data, index) => (
                                        <Draggable
                                          draggableId={
                                            data._id + "#" + data.job_order_id
                                          }
                                          index={index}
                                          key={data._id + data.job_order_id}
                                          // isDragDisabled={dragDisabled}
                                          isDragDisabled={true}
                                        >
                                          {(provided) => (
                                            <div
                                              key={index}
                                              className="kanban-board-data mx-1 my-2 px-2 py-1 rounded"
                                              onMouseEnter={() =>
                                                handleHoveredIndex(
                                                  index,
                                                  columnIndex
                                                )
                                              }
                                              onMouseLeave={() =>
                                                handleHoveredIndex(
                                                  null,
                                                  columnIndex
                                                )
                                              }
                                              data-tooltip-id="kanban-board-tooltip"
                                              data-tooltip-content={`${
                                                data.name
                                              },${" "}${data.job_role}`}
                                              data-tooltip-place="bottom"
                                              {...provided.dragHandleProps}
                                              {...provided.draggableProps}
                                              ref={provided.innerRef}
                                            >
                                              <div className="d-flex justify-content-between align-items-center">
                                                <div className="kanban-board-can-name">
                                                  {data.name}
                                                </div>
                                                {/* {!dragDisabled && (
                                                  <img
                                                    src={drag}
                                                    alt=""
                                                    width={14}
                                                    height={14}
                                                    className={
                                                      hoveredIndexes[
                                                        columnIndex
                                                      ] === index
                                                        ? "d-flex"
                                                        : "d-none"
                                                    } // Show image only if hovered over the current item
                                                  />
                                                )} */}
                                              </div>

                                              <div className="d-flex gap-1 align-items-center">
                                                <img src={job} alt="" />
                                                <div className="kanban-board-jobrole">
                                                  {data.job_role}
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </Draggable>
                                      )
                                    )}
                                    </div>
                                  </>
                                )}
                              </>

                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    ))}
                  </DragDropContext>
                </div>
              </Row>
              <Row>
                <Footer />
              </Row>
            </Container>
          </div>
        )}

        <Modal
          open={showPopup}
          onClose={upgradePlan}
          center
          styles={{ background: "black" }}
        >
          <h4 className="modal-heading">Are you sure ?</h4>
          <p style={{ textAlign: "center", marginTop: "20px" }}>
            Do you want to make a change on ATS status
          </p>
          <div className="btn-div">
            <button onClick={handleEnableDrag} className="model-yes-btn">
              yes
            </button>
            <button onClick={handleClose} className="model-yes-btn">
              No
            </button>
          </div>
        </Modal>
      </Container>
    </div>
  );
};

export default KanbanBoard;
