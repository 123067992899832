import React, { useState } from "react";
import "./EmailTemplate.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { API } from "../../../global";
import toast from "react-hot-toast";
import arrowImage from "../../../assets/icons/ArrowIcon.svg";
import { useNavigate } from "react-router";

const EmailTemplateEdit = (props) => {
  const navigate = useNavigate()

  const [temp_name, setTempName] = useState(props.editdata.template_name);
  const [from_name, setFromName] = useState(props.editdata.from_name);
  const [email, setEmail] = useState(props.editdata.from_email);
  const [email_subject, setEmailSubject] = useState(props.editdata.subject);
  const [email_body, setEmailBody] = useState(props.editdata.body);
  const [loading, setLoading] = useState(false);

  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["blockquote", "code-block"],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  const editTemplate = async () => {
    const obj = {
      template_id: props.editdata._id,
      key: props.editdata.key,
      template_name: temp_name,
      from_mail: email,
      from_name: from_name,
      subject: email_subject,
      body: email_body,
    };

    setLoading(true);
    fetch(`${API}/admin/tenant/email-setting/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          toast.success("Edited Successfully");
          setLoading(false);
          props.fetchData();
        } else {
        
          if (response.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(response.error.msg);
          }
         
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Error occurred please try again");
      });
  };

  const back = () => {
    props.closeEdit();
  };
  return (
    <>
      <div className="d-flex justify-content-between pt-4 px-4">
        <button className="icon-div" onClick={() => back()}>
          <img src={arrowImage} alt="" className="backImage" />
        </button>
        <div></div>
      </div>
      <div style={{ margin: "55px" }}>
        <Row>
          <Col>
            <div className="input-div">
              <label className="label-style">Template Name</label>
              <input
                className="input-style"
                type="text"
                value={temp_name}
                onChange={(e) => setTempName(e.target.value)}
              />
            </div>
            <div className="input-div">
              <label className="label-style">From Name</label>
              <input
                className="input-style"
                type="text"
                value={from_name}
                onChange={(e) => setFromName(e.target.value)}
              />
            </div>
            <div className="input-div">
              <label className="label-style">Reply to email address</label>
              <input
                className="input-style"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <div className="input-div">
            <label className="label-style">Email Subject</label>
            <input
              className="input-style"
              type="text"
              value={email_subject}
              onChange={(e) => setEmailSubject(e.target.value)}
            />
          </div>

          <div className="input-div">
            <label className="label-style">Email Body</label>
            <div className="email-editor-div ">
              <div className="email-temp-textbox">
                <ReactQuill
                  placeholder="Enter your question here"
                  modules={modules}
                  value={email_body}
                  onChange={(e) => setEmailBody(e)}

                  // onChange={(val) => handleChangeQuestion(val, i)}
                />
              </div>
            </div>
            <p>
              <i>Available variable : {props.editdata.available_variables}</i>
            </p>
          </div>
        </Row>
        <Row>
          <Col>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <button
                className="add-save-btn btn btn-md"
                onClick={() => editTemplate()}
                disabled={loading}
              >
                {loading ? "Saving.." : "Save"}
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EmailTemplateEdit;
