import React, { useState, useEffect } from "react";
import Footer from "../../Footer/Footer";
import Nav from "../../Reusable/Navbar/Nav";
import SideMenu from "../../Reusable/SideMenu/SideMenu";
import ProgressBarComponent from "../../Reusable/ProgressBarComponent";
import DataTable from "react-data-table-component";
import plus from "../../../assets/plus.svg";
import edit from "../../../assets/edit.svg";
import { Tooltip } from "react-tooltip";
import redirect from "../../../assets/redirect.svg";
import { useNavigate } from "react-router";
import { API } from "../../../global";
import toast from "react-hot-toast";
import moment from "moment";

const paginationOptions = {
  rowsPerPageText: "Rows per page:",
  rangeSeparatorText: "of",
  // selectAllRowsItem: true,
  // selectAllRowsItemText: '',
  paginationRowsPerPageOptions: [10, 20, 50, 100, 200, 500],
};

const TenantList = () => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [tenantlist, setTenantList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTenantList();

    // eslint-disable-next-line
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const fetchTenantList = () => {
    setLoading(true);
    fetch(`${API}/super-admin/tenant/list`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          let list = [];

          // eslint-disable-next-line
          response?.result?.map((data) => {
            const obj = {
              tenant: data?.name,
              plan: data.current_plan?.name,
              id: data?._id,
              admin_url: data?.admin_url,
              created_at: data?.created_at,
              tenant_interview_count : data?.tenant_interview_count,
              lastActivity :data.tenant_last_activity ? data?.tenant_last_activity?.created_at : ''
            };
            list.push(obj);
          });
          setTenantList(list);
          setLoading(false);
        } else {
          if (response.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(response.error.msg);
          }
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#F8EBFF40 0% 0% no-repeat padding-box",
      },
    },
    rows: {
      style: {
        backgroundColor: "#F8EBFF40 0% 0% no-repeat padding-box",
        textAlign: "center",
      },
    },
    cells: {
      style: {
        fontSize: "14.5px",
        // borderBottom: " 1px solid #eee7f3",
        textAlign: "center",
      },
    },
  };

  const createTenant = () => {
    navigate("/tenant/tenantDetails");
  };

  const handleTenantLogin = (row) => {
    const tokenobj = {
      id: row.id,
      view: "admin",
      auth_token: localStorage.getItem("auth_token"),
    };
    // Encode the object
    const token = encodeURIComponent(JSON.stringify(tokenobj));
    //http://localhost:3001 use for testing for purpose
    window.open(
      `${row.admin_url}?view=${token}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const onClickedit = (data) => {
    navigate(`/tenant/tenantDetails?mode=edit&&id=${data.id}`);
  };

  

  const planDiv = (row) => {
    let planStyle = "";
    if (row?.plan?.toLowerCase() === "premium") {
      planStyle = "premium-tag";
    } else if (row?.plan?.toLowerCase() === "basic") {
      planStyle = "basic-tag";
    } else if (row?.plan?.toLowerCase() === "plus") {
      planStyle = "plus-tag";
    } else if (row?.plan?.toLowerCase() === "freemium") {
      planStyle = "freemium-tag";
    } else {
      planStyle = "freemium-other-tag";
    }

    return (
      <div>
        {row?.plan ? (
          <button disabled className={planStyle}>
            {row.plan}
          </button>
        ) : (
          "--"
        )}

        {/* <div className="premium-tag">{row.plan}</div> */}
      </div>
    );
  };

  const actionTable = (row) => {
    return (
      <div className="d-flex gap-3">
        <Tooltip
          id="login-tooltip"
          variant="light"
          border="1px solid #7C7B7B"
          className="candidate-tooltip"
        />
        <div>
          <button
            style={{ border: "none", background: "none" }}
            onClick={() => handleTenantLogin(row)}
          >
            <img
              src={redirect}
              style={{ cursor: "pointer" }}
              alt="login icon"
              data-tooltip-id="login-tooltip"
              data-tooltip-content="Login as Tenant"
              data-tooltip-place="top"
              className="img-fluid"
            />
          </button>
        </div>
        <Tooltip
          id="edit-tooltip"
          variant="light"
          className="candidate-tooltip"
          border="1px solid #7C7B7B"
        />
        <div>
          <button
            style={{ border: "none", background: "none" }}
            onClick={() => onClickedit(row)}
          >
            <img
              src={edit}
              style={{ cursor: "pointer" }}
              alt="edit icon"
              data-tooltip-id="edit-tooltip"
              data-tooltip-content="Edit"
              data-tooltip-place="top"
              className="img-fluid"
            />
          </button>
        </div>
      </div>
    );
  };

  const columns = [
    // {
    //   name: "S.No",
    //   sortable: true,
    //   selector: (row, i) => i + 1,
    //   cell: (row, index) => {
    //     return calculateSerialNumber(index);
    //   },
    //   center: true,
    //   width: "100px",
    // },
    {
      name: "Tenant",
      sortable: false,
      selector: (row) => row.tenant,
      center: true,
    },
    {
      name: "Plan",
      sortable: false,
      cell: (row) => planDiv(row),
      selector: (row) => row.plan,
      center: true,
    },
    {
      name: "No of interviews",
      sortable: false,
      cell: (row) => row.tenant_interview_count ? row.tenant_interview_count : 0,
      selector: (row) => row.tenant_interview_count,
      center: true,
    },
    {
      name: "Last Activity",
      sortable: false,
      selector: (row) => row.lastActivity ? moment(row.lastActivity).format("DD-MM-YYYY") : '--',
      // sortFunction: (a, b) => moment(a).diff(moment(b)),
      center: true,
    },

    {
      name: "Created Date",
      sortable: true,
      selector: (row) => moment(row.created_at).format("DD-MM-YYYY"),
      sortFunction: (a, b) => moment(a).diff(moment(b)),
      center: true,
      style: {
        header: {
          display: "flex",
          justifyContent: "center",
          marginRight: "30px",
        },
        display: "flex",
        justifyContent: "center",
        marginRight: "30px",
      },
    },
    {
      name: "Actions",
      id: "action",
      sortable: false,
      cell: (row) => actionTable(row),
      width: "150px",
      style: {
        header: {
          display: "flex",
          justifyContent: "flex-end",
          margin: "10px",
          paddingRight: "40px",
        },
        display: "flex",
        justifyContent: "flex-end",
        margin: "10px",
        paddingRight: "40px",
      },
    },
  ];

  return (
    <>
      <Nav />

      <div className="container-fluid text-center d-flex  m-0 p-0">
        <div className="sidemenu-section">
          <SideMenu menuIndex={5} />
        </div>

        <div className="w-100 h-100 ">
          {loading ? (
            <div className="loader-div-job">
              <ProgressBarComponent />
            </div>
          ) : (
            <>
              <div className="joblist-details-section">
                <div
                  className="can-table-class"
                  style={{
                    background: "#ffffff",
                    margin: "20px",
                    padding: "20px",
                    borderRadius: "10px",

                    // height: "100%",
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div></div>
                    <div className="d-flex align-items-end justify-content-center w-25">
                      <div className="tenant-list-label ">Tenants List</div>
                    </div>

                    <button
                      className="btn text-white py-1 add-ques-btn"
                      onClick={createTenant}
                    >
                      <img
                        src={plus}
                        // className="mr-1"
                        alt="plus"
                        style={{
                          width: "23px",
                          color: "#fff",
                          marginRight: "7px",
                        }}
                      />
                      Create Tenant
                    </button>
                  </div>

                  <div className="table-wrap fs-5 ">
                    <DataTable
                      columns={columns}
                      data={tenantlist}
                      defaultSortFieldId={2}
                      customStyles={customStyles}
                      pagination={tenantlist.length >= 10 ? true : false}
                      paginationPerPage={10}
                      paginationRowsPerPageOptions={
                        paginationOptions.paginationRowsPerPageOptions
                      }
                      paginationTotalRows={tenantlist.length}
                      paginationServer={false}
                      paginationComponentOptions={paginationOptions}
                      onChangePage={handlePageChange}
                      paginationDefaultPage={currentPage}
                      
                    />
                  </div>
                </div>
                <div>
                  <Footer />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default TenantList;
