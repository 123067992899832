import React, { useRef } from "react";
import Layout from "../Layout/Layout";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import validator from "email-validator";
import { useNavigate } from "react-router";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const loginRef = useRef();

  const schema = yup
    .object({
      email: yup
        .string()
        .required("Email is a required field")
        .test(
          "email-valid",
          "Please, use standard email format. (for e.g., username@example.com)",
          (value) => validator.validate(value)
        ),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Trigger button click event
      loginRef.current.click();
    }
  };

  const onSubmit = (data) => {
    navigate('/success?type=forgotPassword')
  }

  return (
    <div>
      <Layout>
        <div className="logo-div">
          <div className="login-body">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-12"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <div className="login-card-email">
                    <div
                      className="panel-heading"
                      style={{ textAlign: "center" }}
                    >
                      <p className="signin-label mt-4">Forgot Password</p>
                      <p className="forgot-password-sub">
                        Enter your email and we'll send you a link to reset your
                        password.
                      </p>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="basic-form-div">
                        <label className="label-style mb-1">E-Mail ID</label>
                        <input
                          className="form-control-login"
                          placeholder="Enter your E-Mail ID"
                          {...register("email")}
                          onKeyDown={handleKeyPress}
                        />
                        {errors?.email?.message ? (
                          <div
                            style={{
                              color: "red",
                              marginTop: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {errors.email.message}
                          </div>
                        ) : null}

                        <div className="forgot-password-button-div">
                          <button
                            type="submit"
                            ref={loginRef}
                            className="forgot-submit-btn"
                          >
                            Submit
                          </button>
                          <button type="submit" className="forgot-cancel-btn">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default ForgotPassword;
