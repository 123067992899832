import React, { useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import scheduleImg from "../../assets/ScheduleIcon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import { API } from "../../global";
import crossIcon from '../../assets/closeIcon.svg'
import moment from "moment";
import toast from "react-hot-toast";
import "./Candidatelist.css";
import { useNavigate } from "react-router";

const Scheduleinterview = ({
  candidateName,
  candidateId,
  quesCount,
  setShowOffcanvas,
  showOffcanvas,
  quesDuration,
  candidates,
  idx,
  placement,
  jobid,
  getCandidates,
  setInviteLoading
}) => {
  const flatpickrRef = useRef(null);
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [expDate, setExpDate] = useState("");
  const [loading,setLoading] = useState(false);

  const handleDateChange = (selectedDates) => {
    const isDateSelected =
      Array.isArray(selectedDates) && selectedDates.length > 0;
    setCurrentDate(selectedDates[0]);
    setExpDate(isDateSelected ? selectedDates[0] : "");
    flatpickrRef.current.flatpickr.close();
  };

  const closeOffcanvas = () => {
    setShowOffcanvas(false);
  };
  const scheduleInterview = (str, a) => {
    setLoading(true);
    let canSchedule = [];

    if (str === "single") {
      canSchedule = [candidateId];
    } else {
      // canSchedule = candidates
      candidates.forEach((d) => {
        if (d?._id) {
          canSchedule.push(d?._id);
        }
      });
      // .filter((can) => can && (can.candidate_id || can)._id) // Filter out objects without _id or candidate_id
      // .map((can) => (can.candidate_id && can.candidate_id._id) || can._id); // Map to _id or candidate_id._id
    }
    // setEnable(true);
    const data = {
      job_order_id: jobid,
      candidate_ids: canSchedule,
      interview_link_expiry: moment(expDate).format("YYYY-MM-DD"),
    };
    fetch(`${API}/admin/interview/schedule`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          setLoading(false);
          setShowOffcanvas(false);
          toast.success("Mail sent successfully");
          getCandidates();
          setInviteLoading(true)
          setExpDate('')
          // setEnable(false);
        }else{
          
          if (res.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(res.error.msg)
          }
          setLoading(false);   
        }
      });
  };
  return (
    <>
      <Offcanvas
        show={showOffcanvas}
        onHide={closeOffcanvas}
        key={idx}
        placement={placement}
        name={placement}
      >
        <div
          className="w-100 px-3"
          data-bs-scroll="true"
          tabindex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="canvas-div">
            <button
              onClick={() => setShowOffcanvas(false)}
              className="canvas-header"
            >
              <img src={crossIcon} alt="close" />
            </button>
          </div>

          <div className="offcanvas-body">
            {candidates.length > 1 ? (
              ""
            ) : (
              <div className="mb-3">
                <label
                  for="exampleFormControlInput1"
                  className="form-label size-text"
                >
                  Candidate Name
                </label>
                <p className="">{candidateName}</p>
              </div>
            )}
            <div>
              <label
                for="exampleFormControlInput1"
                className="form-label size-text"
              >
                Link Expiry Date
              </label>
              <div className="input-with-icon">
                <FontAwesomeIcon icon={faCalendar} className="calendar-icon" />
              </div>

              <Flatpickr
                ref={flatpickrRef}
                options={{
                  enableTime: true,
                  minDate: currentDate,
                  altInput: true,
                  altFormat: "Y-m-d",
                }}
                value={expDate}
                onChange={handleDateChange}
                className="w-100 rounded border border-1 sche-input py-2 px-1 border-[#E9E9E9]"
                placeholder="Select link expiry date"
              />
            </div>

            <div className="canvas-question-label mt-4 w-100">
              <p className="pt-2 size-text">
                {" "}
                No of Questions :{" "}
                <span className="schedule-label">{quesCount}</span>
              </p>
            </div>

            <div className="canvas-question-label pt-1">
              <p className="pt-2 size-text">
                {" "}
                Total Duration :{" "}
                <span className="schedule-label">{`${quesDuration} mins`}</span>
              </p>
            </div>

            {candidates.length > 1 && (
              <div className="canvas-question-label pt-1">
                <p className="pt-2 size-text">
                  {" "}
                  No of Candidates:{" "}
                  <span className="schedule-label">{candidates.length}</span>
                </p>
              </div>
            )}

            <div className="mt-3">
              <button
                className={`schedule-btn px-3 py-2 ${
                  !expDate && "disable-btn"
                }`}
                onClick={() =>
                  scheduleInterview(candidates.length < 1 ? "single" : "bulk")
                }
                disabled={!expDate}
              >
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <>
                    {" "}
                    <img src={scheduleImg} alt="Schedule" />
                    <span className="ms-2">Schedule Interview</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </Offcanvas>
    </>
  );
};

export default Scheduleinterview;
