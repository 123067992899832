import React, { useEffect, useState } from "react";
import "./SideMenu.css";
import dashIcon from "../../../assets/dash-icon.svg";
import joblist from "../../../assets/jobList.svg";
import settings from "../../../assets/dash-settings.svg";
import addTenant from "../../../assets/tenantAdd.svg";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router";
import questionnaire from "../../../assets/Manage Questionnaires - Purple.svg";
import kanbanboard from "../../../assets/kanban-boarddash.svg";
import plan from "../../../assets/plan.svg";
import logIcon from "../../../assets/activityLog.svg";

const SideMenu = (props) => {
  const navigate = useNavigate();
  const [iconIndex, setIconIndex] = useState(1);
  const { menuIndex } = props;
  const role = localStorage.getItem("current_role_key");
  const [dashboardUrl, setDashboardUrl] = useState("/admin-dashboard");
  // console.log("role17",role)

  useEffect(() => {
    setIconIndex(menuIndex);
    if (role === "talentou_super_admin") {
      setDashboardUrl("/superAdmin-dashboard");
    }

    // eslint-disable-next-line
  }, [menuIndex]);

  const onClickNavigate = (route) => {
    navigate(route);
  };

  return (
    <>
      <Tooltip
        id="sidemenu-tooltip"
        variant="light"
        border="1px solid #7C7B7B"
        className="logout-tooltip"
      />

      <div className="pt-4 px-3 gap-4 side-menu-container">
        <div className={iconIndex === 1 ? "active-link step-1" : "step-1"}>
          <button
            style={{ border: "none", background: "none" }}
            onClick={() => onClickNavigate(dashboardUrl)}
          >
            <img
              src={dashIcon}
              alt="dashboard"
              className="side-menu-icon"
              data-tooltip-id="sidemenu-tooltip"
              data-tooltip-content="Dashboard"
              data-tooltip-place="bottom"
            />
          </button>
        </div>
        {role !== "talentou_super_admin" && (
          <div className={iconIndex === 2 ? "active-link step-2" : "step-2"}>
            <button
              style={{ border: "none", background: "none" }}
              onClick={() => onClickNavigate("/jobslist")}
            >
              <img
                src={joblist}
                alt="joblist"
                width="20px"
                className="side-menu-icon"
                data-tooltip-id="sidemenu-tooltip"
                data-tooltip-content="Job List"
                data-tooltip-place="bottom"
              />
            </button>
          </div>
        )}

        {(role === "talentou_admin" || role === "talentou_recruiter") && (
          <div className={iconIndex === 8 ? "active-link step-3" : "step-3"}>
            <button
              style={{ border: "none", background: "none" }}
              onClick={() => onClickNavigate("/kanban-board")}
            >
              <img
                src={kanbanboard}
                alt="kanban-board"
                className="side-menu-icon"
                data-tooltip-id="sidemenu-tooltip"
                data-tooltip-content="Kanban Board"
                data-tooltip-place="bottom"
              />
            </button>
          </div>
        )}

        {role === "talentou_tech_lead" && (
          <div className={iconIndex === 3 ? "active-link step-9" : "step-9"}>
            <button
              style={{ border: "none", background: "none" }}
              onClick={() => onClickNavigate("/questionnaires")}
            >
              <img
                src={questionnaire}
                alt="joblist"
                width="20px"
                className="side-menu-icon"
                data-tooltip-id="sidemenu-tooltip"
                data-tooltip-content="Manage Questionnaires"
                data-tooltip-place="bottom"
              />
            </button>
          </div>
        )}

        {role === "talentou_admin" && (
          <>
            <div className={iconIndex === 4 ? "active-link step-4" : "step-4"}>
              <button
                style={{ border: "none", background: "none" }}
                onClick={() => onClickNavigate("/settings/interview")}
              >
                <img
                  src={settings}
                  alt="settings"
                  width="20px"
                  data-tooltip-id="sidemenu-tooltip"
                  data-tooltip-content="Settings"
                  data-tooltip-place="bottom"
                />
              </button>
            </div>
            <div className={iconIndex === 7 ? "active-link step-5" : "step-5"}>
              <button
                style={{ border: "none", background: "none" }}
                onClick={() => onClickNavigate("/audit-log")}
              >
                <img
                  src={logIcon}
                  alt="settings"
                  width="20px"
                  data-tooltip-id="sidemenu-tooltip"
                  data-tooltip-content="Activity Log"
                  data-tooltip-place="bottom"
                />
              </button>
            </div>
          </>
        )}

        {role === "talentou_super_admin" && (
          <>
            <div className={iconIndex === 5 ? "active-link" : ""}>
              <button
                style={{ border: "none", background: "none" }}
                onClick={() => onClickNavigate("/tenant")}
              >
                <img
                  src={addTenant}
                  alt="settings"
                  width="20px"
                  data-tooltip-id="sidemenu-tooltip"
                  data-tooltip-content="Tenants"
                  data-tooltip-place="bottom"
                />
              </button>
            </div>

            <div className={iconIndex === 6 ? "active-link px-1" : ""}>
              <button
                style={{ border: "none", background: "none" }}
                onClick={() => onClickNavigate("/plans")}
              >
                <img
                  src={plan}
                  alt="settings"
                  width="20px"
                  data-tooltip-id="sidemenu-tooltip"
                  data-tooltip-content="Plans"
                  data-tooltip-place="bottom"
                />
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SideMenu;
