import React, { useEffect, useState } from "react";
import "./AddQuestion.css";
import ReactQuill from "react-quill";
import { textEditorHeader } from "../../Functions/Constant";
import minus from "../../../assets/minusDuration.svg";
import add from "../../../assets/addduration.svg";
import SwitchComponent from "../../Reusable/SwitchComponent";
import TextInput from "../../Jobslist/TextInput";
import { API } from "../../../global";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
// import aigreen from "../../../assets/AI Icon - Green - Default.svg";
// import aigreenhover from "../../../assets/AI Icon - Green - Hover.svg";
// import { IoSend } from "react-icons/io5";

const AddNewQuestionModal = ({
  setShowModal,
  setAddQuesList,
  getQuestionnaire,
  questionnaireId,
  modalTitle,
}) => {
  //   const [isHovered, setIsHovered] = useState(false);
  //   const handleHover = () => {
  //     setIsHovered(!isHovered);
  //   };const [questionnaireName, setQuestionnaireName] = useState("");
  const [timecount, setTimeCount] = useState(1);
  const navigate = useNavigate()
  const [questionText, setQuestionText] = useState("");
  const [answerData, setAnswerData] = useState([]);
  const [textInput, setTextInput] = useState(false);
  const [answerKeyword, setAnswerKeyWord] = useState("");
  const [apiType, setApiType] = useState("create");
  const [questionId, setQuestionId] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [interviewDuration, setInterviewDuration] = useState(0);


  useEffect(() => {
    if (modalTitle === "Edit") {

      const questionDataString = localStorage.getItem("questionDetails");

      // Convert the string back to an object using JSON.parse()
      const questionDetails = JSON.parse(questionDataString);
      setQuestionText(questionDetails.question)
      setAnswerData(JSON.parse(questionDetails.answer));
      setTextInput(questionDetails.textinput);
      setTimeCount(questionDetails.duration)
      setQuestionId(questionDetails.questionId);
      setApiType("update");
    } else {
      setApiType("create");
    }

    fetchData();
    // eslint-disable-next-line
  }, []);

  console.log(questionText,"questionText")

  const handleKeyDown = (e) => {
    if (answerKeyword.trim() !== "") {
      if (e.key === "Enter") {
        setAnswerData([...answerData, answerKeyword]);
        setAnswerKeyWord("");
      }
    }
  };

  const handleInputChangeKeyWord = (e) => {
    setAnswerKeyWord(e.target.value);
  };

  const removeKeyWord = (index) => {
    // Assuming answerData is a state variable
    setAnswerData((prevData) => prevData.filter((_, i) => i !== index));
  };

  const handleMinusClick = () => {
    if (timecount > 1) {
      setTimeCount(timecount - 1);
    }
  };

  const handlePlusClick = () => {
    if (timecount < interviewDuration) {
      setTimeCount(+timecount + 1);
    }
  };

  const modules = textEditorHeader();

  const handleQuesChange = (content) => {
    // content is the updated HTML content of the editor
    setQuestionText(content);
  };

  const handleTextInput = (isChecked) => {
    setTextInput(isChecked);
  };

  const fetchData = () => {
    fetch(`${API}/admin/tenant/interview-setting`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setInterviewDuration(res.result?.max_duration_per_qn);
        }else{
          if (res.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(res.error.msg)
          }
        }
      })
      .catch(() => {
        toast.error("Error occurred please try again");
      });
  };

  const handleAddQuestion = (apiType) => {
    setProcessing(true);
    const obj = {
      questionnaire_id: questionnaireId,
      question_id: questionId,
      question: questionText,
      answer_key: answerData.toString(),
      text_box_required: textInput,
      maximum_duration: timecount * 60,
    };

    fetch(`${API}/admin/questionnaire/question/${apiType}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((val) => {
        if (val.success) {
          setAddQuesList(true);
          setShowModal(false);

          if (apiType === "create") {
            toast.success(`Question added successfully`);
          } else {
            toast.success(`Question edited successfully`);
          }

          getQuestionnaire();
          setProcessing(false);
        } else {
        
          if (val.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(val.error.msg);
          }
          setProcessing(false);
        }
      })
      .catch((err) => {
        toast.error(err.msg);
        setProcessing(false);
      });
  };

  console.log(textInput, "con123");
  return (
    <div className="modal-edit-background">
      <div className="table-label mb-3">
        <h5>{modalTitle} Question </h5>
      </div>

      <div>
        <h6 style={{ marginBottom: "20px" }}>Question</h6>
        <div className="question-modal-textbox">
          <ReactQuill
            placeholder="Enter your question here"
            modules={modules}
            value={questionText}
            onChange={handleQuesChange}
          />
        </div>

        <h6 className="py-2">Answer (Type the answer and press enter)</h6>
        <div className="mb-3">
          <TextInput
            handleKeyDown={handleKeyDown}
            handleInputChangeKeyWord={handleInputChangeKeyWord}
            removeKeyWord={removeKeyWord}
            answerKeyword={answerKeyword}
            answerData={answerData}
            id="answerKey"
            placeHolder={"Type the answer and press enter"}
          />
        </div>

        {/* <div className="py-2 w-100 prompt-text-editor px-2">
          <div>
            <img
              src={isHovered ? aigreenhover : aigreen}
              alt=""
              className="pe-3"
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}
              style={{cursor:'pointer'}}
            />
            Enter your prompt
          </div>
          <div><IoSend className="send-icon"/></div>
        </div> */}

        <div className="add-question-footer mt-3">
          <div className="d-flex">
            <div className="time-duration-add-ques me-3 rounded">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleMinusClick()}
              >
                <img src={minus} alt="" className="px-3" />
              </div>
              <div className="add-ques-time-count px-2 h-100 pt-2">
                {timecount} <span>min</span>{" "}
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handlePlusClick()}
              >
                <img src={add} alt="" className="px-3" />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="fs-6 fw-bold me-2">Text input</div>
              <div className="">
           
            <SwitchComponent
                disabled={false}
                className="toggle-btn"
                type="addQuestion"
                value={textInput}
                switchButton={handleTextInput}
                switchText={true}
              />
              </div>
            </div>
          </div>
          <div className="d-flex gap-2">
            <button
              className="cancel-btn-addques"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
            <button
              className={
                modalTitle === "Add" ? "add-btn-addques" : "save-question-btn"
              }
              onClick={() => handleAddQuestion(apiType)}
            >
              {modalTitle === "Add"
                ? processing
                  ? "Adding..."
                  : "Add"
                : processing
                ? "Updating..."
                : "Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewQuestionModal;
