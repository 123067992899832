import React, { useEffect, useState, useMemo } from "react";
// import DataTable from "react-data-table-component";
import { Tooltip } from "react-tooltip";
import download from "../../../src/assets/file-download-purple.svg";
import edit from "../../../src/assets/edit-purple.svg";
import downloadwhite from "../../../src/assets/file-download.svg";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router";

import pin from "../../../src/assets/pin-angle.svg";
import pinpurple from "../../../src/assets/pin-angle-purple.svg";

import "./Jobslist.css";
import { API } from "../../global";

import toast from "react-hot-toast";
import Nav from "../Reusable/Navbar/Nav";
import SearchInput from "../Reusable/SearchInput";
import Footer from "../Footer/Footer";
import SideMenu from "../Reusable/SideMenu/SideMenu";
import ProgressBarComponent from "../Reusable/ProgressBarComponent";
import RequiredSkillsCard from "./RequiredSkillsCard";
import { CSVLink } from "react-csv";
import view from "../../../src/assets/view.svg";
import plus from "../../../src/assets/plus.png";

export default function Jobslist() {
  const [joblist, setJoblist] = useState([]);
  const [jobId, setJobId] = useState("");
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobRole, setJobRole] = useState();
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [isHovered, setIsHovered] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [skillData, setSkillData] = useState(null);
  const [pinnedData, setPinnedData] = useState([]);
  const [otherData, setOtherData] = useState([]);

  const role = localStorage.getItem("role_id");
  const handleOpenModal = (row) => {
    if (row.id) {
      const skill = {
        optionalSkill: row.optional_skills,
        requiredSkill: row.required_skills,
      };
      setSkillData(skill);
      setShowModal(true);
      setJobRole(row.job_role);
      setJobId(row.id);
      setSelectedJob(row);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const storedPinnedRows = JSON.parse(localStorage.getItem("pinnedRows")) || [];
  const [pinnedRows, setPinnedRows] = useState(storedPinnedRows);

  useEffect(() => {
    // Save pinned rows to localStorage whenever the state changes
    localStorage.setItem("pinnedRows", JSON.stringify(pinnedRows));
  }, [pinnedRows]);

  useEffect(() => {
    if (!showModal) {
      getRequiredSkillsForRow(jobId);
    }
  }, [showModal, jobId]);

  // Modify this function to get the required skills for a specific row
  const getRequiredSkillsForRow = (rowId) => {
    // Customize this logic based on how you store and retrieve required skills
    const reqSkillsKey = `skillData_${rowId}`;
    const storedReqSkills =
      JSON.parse(localStorage.getItem(reqSkillsKey)) || [];
    return storedReqSkills;
  };

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const handlepinnedRow = (row) => {
    const pinRow = {
      job_order_id: row._id,
      pin_row: row.pin_row === 1 ? 0 : 1,
    };
    let toastMsg = "";
    if (row.pin_row === 1) {
      toastMsg = "Row pinned Successfully";
    } else {
      toastMsg = "Row unpinned Successfully";
    }
    fetch(`${API}/admin/job-order/update-pin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify(pinRow),
    })
      .then((res) => res.json())
      .then((val) => {
        if (val.success === true) {
          handleCloseModal();
          setLoading(false);
          toast.success(toastMsg);
          fetchJob();
        } else {
          
          if (val.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(val.error.msg);
          }
          setLoading(false);
        }
      })
      .catch(() => {
        toast.error("Error occurred please try again");
        setLoading(false);
      });
  };

  useEffect(() => {
    getJobs();

    // eslint-disable-next-line
  }, []);

  const navigate = useNavigate();

  const getJobs = () => {
    setLoading(true);
    fetch(`${API}/admin/job-order/list`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((val) => {
        if (val.success === true) {
          let pinData = [];
          const pinnedRows = val.result.filter((row) => row.pin_row === 0);
          const otherRows = val.result.filter((row) => row.pin_row !== 0);
          setPinnedData(pinnedRows);
          setOtherData(otherRows);

          val.result.length > 0 ? setJoblist(val.result) : setJoblist([]);
          // eslint-disable-next-line
          val.result.map((data) => {
            if (data.pin_row === 0) {
              pinData.push(data._id);
            }
          });
          setPinnedRows(pinData);
          setLoading(false);
        } else {
          
          if (val.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(`${val.error.msg}`);
          }
        }
      });
  };

  const navigateJob = (row) => {
    return (
      <>
        <Tooltip
          id="jobrole-column-tooltip"
          variant="light"
          border="1px solid #7C7B7B"
          className="candidate-tooltip "
        />
        <div
          data-tooltip-id="jobrole-column-tooltip"
          data-tooltip-content={`${row.job_role}`}
          data-tooltip-place="top"
          onClick={() => {
            navigate(`/interview/candidatelist/${row._id}`);
          }}
          className="custom-cell-job-role"
        >
          {row.job_role}
        </div>
      </>
    );
  };

  const companyNameColumn = (row) => {
    return (
      <>
        <Tooltip
          border="1px solid #7C7B7B"
          id="company-name-column-tooltip"
          variant="light"
          className="candidate-tooltip"
        />
        <div
          data-tooltip-id="company-name-column-tooltip"
          data-tooltip-content={`${row.company_name}`}
          data-tooltip-place="top"
          className="custom-table-cell"
        >
          {row.company_name}
        </div>
      </>
    );
  };

  const locationColumn = (row) => {
    return (
      <>
        <Tooltip
          border="1px solid #7C7B7B"
          id="loc-column-tooltip"
          variant="light"
          className="candidate-tooltip"
        />
        <div
          data-tooltip-id="loc-column-tooltip"
          data-tooltip-content={`${row.location}`}
          data-tooltip-place="top"
          className="custom-table-cell"
        >
          {row.location}
        </div>
      </>
    );
  };

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#F8EBFF40 0% 0% no-repeat padding-box",
      },
    },
    rows: {
      style: {
        backgroundColor: "#F8EBFF40 0% 0% no-repeat padding-box",
        textAlign: "center",
      },
    },
    cells: {
      style: {
        fontSize: "14.5px",
        borderBottom: " 1px solid #eee7f3",
      },
    },
  };

  const columns = [
    {
      name: <img src={pin} alt="" style={{ cursor: "pointer" }} />,
      // Custom header cell with a pin icon
      button: true,
      cell: (row) => {
        const isRowPinned = pinnedRows.includes(row.id);
        const iconSrc = isRowPinned ? pinpurple : pin;
        // const isFirstRow = index === 0;
        return (
          <img
            src={iconSrc}
            alt=""
            onClick={() => handlepinnedRow(row)}
            style={{ cursor: "pointer" }}
          />
        );
      },
    },

    {
      name: "Company Name",

      cell: (row) => companyNameColumn(row),
      selector: "company_name",
      // width: "300px",
      center: true,
      // sort: true,
      sortable: true,
    },
    {
      name: "Job Role",

      cell: (row) => navigateJob(row),
      selector: "job_role",
      // width: "300px",
      center: true,
      // sort: true,
      sortable: true,
    },
    {
      name: "Required Skills",
      cell: (row, index) => {
        const rowRequiredSkills = row.required_skills; // Customize this function to get the required skills for the specific row
        const cellStyles = {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100%", // Adjust this value as needed
        };
        return (
          <>
            <Tooltip
              border="1px solid #7C7B7B"
              id={`req-skill`}
              variant="light"
              className="candidate-tooltip"
            />
            <div style={cellStyles}>
              {/* {rowRequiredSkills?.map((skill, i, array) => ( */}
              <span
                data-tooltip-id={`req-skill`}
                data-tooltip-content={rowRequiredSkills}
                data-tooltip-place="top"
              >
                {rowRequiredSkills}
              </span>
              {/* ))} */}
              {/* {rowRequiredSkills} */}
            </div>
            <Tooltip
              border="1px solid #7C7B7B"
              id="skill-edit"
              variant="light"
              className="candidate-tooltip"
            />
            <img
              src={edit}
              alt="edit icon"
              style={{ marginLeft: "10px", cursor: "pointer" }}
              onClick={() => handleOpenModal(row)}
              data-tooltip-id={"skill-edit"}
              data-tooltip-content="Edit"
              data-tooltip-place="top"
              className="img-fluid"
            />
          </>
        );
      },
      width: "300px",
      center: true,
    },
    {
      name: "Location",
      sort: false,
      cell: (row) => locationColumn(row),
      selector: "location",
      // width: "200px",
      center: true,

      sortable: true,
    },

    {
      name: "Actions",
      cell: (act) => actionTable(act),
      // width: "300px",
      center: true,
    },
  ];

  const handleQuestion = (type, act) => {
    if (type === "edit") {
      navigate(`/question?mode=edit&&id=${act?.questionnaire?.id}`);
      // editQuestionnanaire(act?.questionnaire?.id)
    } else {
      navigate(`/question?mode=add&&role=${act._id}`);
    }
  };

  const actionTable = (act) => {
    return (
      <>
        <Tooltip
          border="1px solid #7C7B7B"
          id="ques-tooltip"
          variant="light"
          className="candidate-tooltip"
        />
        <div className="d-flex gap-4">
          <button
            style={{ border: "none", background: "none" }}
            onClick={() => {
              navigate(`/interview/candidatelist/${act._id}`);
            }}
          >
            <img
              src={view}
              alt="edit icon"
              style={{ cursor: "pointer" }}
              data-tooltip-id="ques-tooltip"
              data-tooltip-content="View Candidates"
              data-tooltip-place="top"
            />
          </button>

          {role === "talentou_tech_lead" && (
            <>
              <button
                style={{ border: "none", background: "none" }}
                onClick={() =>
                  handleQuestion(act?.questionnaire ? "edit" : "add", act)
                }
                // onClick={() => editQuestionnanaire(act?.questionnaire?.id)}
              >
                <img
                  src={act?.questionnaire ? edit : plus}
                  style={{ cursor: "pointer", height: "15px", width: "15px" }}
                  alt="delete icon"
                  data-tooltip-id="ques-tooltip"
                  data-tooltip-content={
                    act?.questionnaire
                      ? "Edit Questionnaire"
                      : "Add Questionnaire"
                  }
                  data-tooltip-place="top"
                />
              </button>
              {/* {act?.questionnaire ? (
                  <button
                    style={{ border: "none", background: "none" }}
                    onClick={() => editQuestionnanaire(act?.questionnaire?.id)}
                  >
                    <img
                      src={edit}
                      style={{ cursor: "pointer" }}
                      alt="delete icon"
                      data-tooltip-id="ques-tooltip"
                      data-tooltip-content="Edit Questionnaire"
                      data-tooltip-place="top"
                    />
                  </button>
              ) : (
                  <button
                    style={{ border: "none", background: "none" }}
                    onClick={() => {
                      navigate(`/addnewquestion/${act._id}`);
                    }}
                  >
                    <img
                      src={plus}
                      style={{ cursor: "pointer" }}
                      alt="add icon"
                      data-tooltip-id="ques-tooltip"
                      data-tooltip-content="Add Questionnaire"
                      data-tooltip-place="top"
                    />
                  </button>
              )} */}
            </>
          )}
        </div>
      </>
    );
  };

  const filteredItems = useMemo(() => {
    // Sort the data array based on pinnedRows
    const sortedData = [...pinnedData, ...otherData];

    // Apply the filter
    return sortedData.filter(
      (item) =>
        JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
        -1
    );

    // eslint-disable-next-line
  }, [joblist, filterText, pinnedRows]);

  const subHeaderComponent = useMemo(() => {
    return (
      <SearchInput
        onFilter={(e) => setFilterText(e.target.value)}
        filterText={filterText}
      />
    );

    // eslint-disable-next-line
  }, [filterText]);

  const filteredItemsWithPinned = useMemo(() => {
    // Split the items into two arrays: pinned and unpinned
    const pinned = [];
    const unpinned = [];

    filteredItems.forEach((item) => {
      if (pinnedRows.includes(item.id)) {
        pinned.push(item);
      } else {
        unpinned.push(item);
      }
    });

    // Concatenate pinned and unpinned arrays
    return [...pinned, ...unpinned];
    // eslint-disable-next-line
  }, [filteredItems, pinnedRows]);

  const csvHeaders = [
    { label: "CompanyName", key: "CompanyName" },
    { label: "JobRole", key: "JobRole" },
    { label: "RequiredSkills", key: "RequiredSkills" },
    { label: "Location", key: "Location" },
  ];
  // Function to generate CSV data from filteredItems
  const generateCsvData = (items) => {
    return items.map((item) => ({
      // Map your data fields here based on your DataTable columns
      // For example:
      CompanyName: item.company_name,
      JobRole: item.job_role,
      RequiredSkills: "",
      Location: item.location,
      // Review: reviewColumn(item),
      // Actions: actionColumn(item),
    }));
  };

  useEffect(() => {
    // Call the function when filteredItems change
    const data = generateCsvData(filteredItemsWithPinned);
    setCsvData([...data]);
  }, [filteredItemsWithPinned]);

  const customRowRenderer = (row) => {
    const isPinned = pinnedRows.includes(row.id);

    return (
      <div
        key={row.id}
        style={{ backgroundColor: isPinned ? "#ffd700" : "transparent" }}
      >
        {columns.map((column, colIndex) => (
          <div key={colIndex}>
            {colIndex === 0 && (
              <img
                src={isPinned ? pinpurple : pin}
                alt=""
                onClick={() => handlepinnedRow(row)}
              />
            )}
            {column.cell(row)}
          </div>
        ))}
      </div>
    );
  };

  const fetchJob = () => {
    getJobs();
  };

  const customSort = (rows, field, direction) => {
    const pinnedRows = rows.filter((row) => row.pin_row === 0);
    const otherRows = rows.filter((row) => row.pin_row === 1);
    let sortedData;
    if (direction === "desc") {
      sortedData = otherRows.sort((a, b) => b[field]?.localeCompare(a[field]));
    } else {
      sortedData = otherRows.sort((a, b) => a[field]?.localeCompare(b[field]));
    }

    return [...pinnedRows, ...sortedData];
  };

  const handleTour = () => {
    localStorage.setItem("tourGuid", true);
    navigate("/admin-dashboard");
  };

  return (
    <div className="position-relative">
      <Nav handleTour={handleTour} />

      <div className="container-fluid text-center d-flex  m-0 p-0">
        <div className="sidemenu-section">
          <SideMenu menuIndex={2} />
        </div>
        {loading ? (
          <>
            <div className="loader-div-job">
              <ProgressBarComponent />
            </div>
          </>
        ) : (
          <div className="joblist-details-section">
            <div
              className="can-table-class"
              style={{
                background: "#ffffff",
                margin: "20px",
                padding: "20px",
                borderRadius: "10px",

                // height: "100%",
              }}
            >
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className="job-csv-download py-1 px-2"
                  onMouseEnter={handleHover}
                  onMouseLeave={handleMouseLeave}
                >
                  <img
                    src={isHovered ? downloadwhite : download}
                    alt=""
                    width={14}
                  />
                  <CSVLink
                    data={csvData}
                    headers={csvHeaders}
                    filename={"joblist.csv"}
                    style={{
                      color: isHovered ? "white" : "#46006E",
                      textDecoration: "none",
                    }}
                  >
                    Download CSV
                  </CSVLink>
                </div>
                <div className="job-list-label ms-2">
                  Job List ({joblist.length})
                </div>
                <div className="d-flex gap-3 align-items-end">
                  <div className="">{subHeaderComponent}</div>
                </div>
              </div>

              <div className="table-wrap fs-5 ">
                <DataTable
                  columns={columns.filter((column) => column !== null)}
                  data={filteredItemsWithPinned}
                  customStyles={customStyles}
                  subHeaderComponent={subHeaderComponent}
                  zy
                  pagination={joblist.length >= 10 ? true : false}
                  paginationPerPage={rowsPerPage}
                  paginationTotalRows={joblist.length}
                  paginationDefaultPage={currentPage}
                  customRowRenderer={customRowRenderer}
                  onChangePage={(page) => setCurrentPage(page)}
                  sortFunction={customSort}
                />
              </div>
            </div>
            <div>
              <Footer />
            </div>
          </div>
        )}
      </div>
      {showModal && (
        <div className={`${showModal ? "d-block" : "d-none"} backdrop-edit`}>
          <RequiredSkillsCard
            jobrole={jobRole}
            selectedJob={selectedJob}
            jobId={jobId}
            handleCloseModal={handleCloseModal}
            skillData={skillData}
            fetchJob={fetchJob}
          />
        </div>
      )}
    </div>
  );
}
